import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import service from '../../../api/services';
import Wrapper from '../../../components/common/Wrapper/Wrapper';
import organisms from "../../../components/organisms";
import { AllPermissions } from '../../../constants/permission';

const { AddSubAdmin } = organisms

const Index = () => {
    const navigate = useNavigate()
    const { addToast } = useToasts();
    const [storeList, setStoreList] = useState()
    const [structuredPermission, setStructuredPermission] = useState<any>([])
    const [defaultPermission, setDefaultPermission] = useState<any>([])

    let params = useParams();
    let staffEditID = params.id || ''
    const [buttonClick, setButtonClick] = useState(false)

    const [staff, setStaff] = useState<any>({
        login_id: "",
        username: "",
        mobile: "",
        email: "",
        password: "",
        role: "",
        store_id: [],
        permissions: Object?.values(AllPermissions)?.filter(permission => permission !== AllPermissions?.store_Sub_Admin_Tab)
    })

    const getStoreDropdown = (response: [], name: string) => {
        let dropdown: any = []
        response && response.map((value: any) => {
            dropdown.push({
                "value": value.id,
                "label": value.store_location,
            }
            )
        })
        return dropdown
    }
    const addSubAdmin = async () => {
        let storeID = staff.store_id.map((item: { value: string }) => item.value)
        let payload = {
            mobile: staff?.mobile,
            email: staff?.email,
            password: staff?.password,
            username: staff?.username,
            login_id: staff?.login_id,
            store_ids: storeID,
            permissions: staff?.permissions,
            // role: staff?.value,
        }

        try {
            let response: any
            if (staffEditID) {
                response = await service.staff.subAdminEdit(staffEditID, payload)
                if (response.status === 200) {
                    addToast(response?.data?.message, {
                        appearance: 'success',
                        autoDismiss: true,
                        autoDismissTimeout: 3000,
                    });
                    navigate(-1)
                }
            } else {
                response = await service.staff.subAdminAdd(payload)
                if (response.status === 201) {
                    addToast(response?.data?.message, {
                        appearance: 'success',
                        autoDismiss: true,
                        autoDismissTimeout: 3000,
                    });
                    navigate(-1)
                }
            }
        } catch (error: any) {
            console.log(error)
            addToast(error?.response?.data?.message, {
                appearance: 'error',
                autoDismiss: true,
                autoDismissTimeout: 3000,
            });
        }
        setButtonClick(false)
    }
    const getDataById = async () => {
        try {
            let response = await service.staff.getDetailById(staffEditID)
            handleEditData(response?.data?.data.staffDetails.user)
            setDefaultPermission(response?.data?.data?.permissions)
        } catch (error) {
            console.log('error', error)
        }
    }

    const handleEditData = (response: any) => {
        let storeID: any = []
        response && response?.store_staffs?.map((value: any) => {
            storeID.push({
                "key": value.store.id,
                "value": value.store.id,
                "label": value.store.store_location,
            })
        })
        let tempEdit = {
            // legal_status: getDropdownValue(Store_Legal_Status, response?.legal_status),
            mobile: response?.mobile,
            email: response?.email,
            // password: response?.password,
            username: response?.username,
            login_id: response?.login_id,
            store_id: storeID,
        }
        setStaff(tempEdit)
    }

    const handleChangePassword = async (password: any) => {
        let payload = {
            password: password
        }
        try {
            let response = await service.staff.changePassword(staffEditID, payload)
            if (response.status === 200) {
                addToast('Password Change successfully', {
                    appearance: 'success',
                    autoDismiss: true,
                    autoDismissTimeout: 3000,
                });
            }
        } catch (error) {
            console.log('error', error)
        }
    }

    const getStoreList = async () => {
        const StoreResponse = await service.store.storeList()
        setStoreList(getStoreDropdown(StoreResponse?.data?.data, "store_name"))
    }

    const removePermissionsByCodes = (obj: any[], permCodes: any[]): any[] => {
        return obj.filter(item => !permCodes.includes(item?.perm_code));
      };

    // for add permission
    const getStructuredPermission = async () => {
        const permCodesToRemove =[AllPermissions?.store_Sub_Admin_Tab]
        try {
            const response = await service.staff.structuredPermission({ for_store_portal: true })
            if (response.status === 200) {
                setStructuredPermission(removePermissionsByCodes(response?.data?.data,permCodesToRemove))
            }
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {

        if (buttonClick) {
            addSubAdmin()
        }

    }, [buttonClick])

    useEffect(() => {
        getStoreList()
        if (staffEditID) {
            getDataById()
        }
        getStructuredPermission()
    }, [])
    return (
        <Wrapper>
            <AddSubAdmin
                staff={staff}
                setStaff={setStaff}
                setButtonClick={setButtonClick}
                handleChangePassword={(password: any) => handleChangePassword(password)}
                storeList={storeList}
                structuredPermission={structuredPermission}
                defaultPermission={defaultPermission}
            />
        </Wrapper>
    )
}
export default Index