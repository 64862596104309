const Barcode = {
    view: () => 'store/barcode-list',
    statusVerificationService: () => 'store/inventory-products/verification-status',
    barcodeStatusById: (id: string) => `store/inventory/inventory-products/${id}/status`,

    // TODO: Lost barcode
    getLostBarcode:()=>'store/barcode/lost',
    searchLostBarcode:()=>'inventory/barcode/search',
    markBarcodeLost:()=>'inventory/barcode/lost'
}
export default Barcode