import React, { useEffect, useState } from "react";
import { BsArrowDownShort, BsArrowUpShort, BsXCircle } from 'react-icons/bs';
import atoms from "../../atoms";
import './index.scss'

import { FiEdit } from "react-icons/fi";
import { RiDeleteBin6Line } from "react-icons/ri";

const { CustomImages, Button } = atoms

const Index = ({
    storeBrandList,
    unrecognized = false,
    hadleAction
}: any) => {
    const [tableData, setTableData] = useState<any>()
    const [sortAsc, setSortAsc] = useState(true);
    const [sortTypeAsc, setSortTypeAsc] = useState(true);
    const sortProductTypesByName = () => {
        const sortedData = [...tableData].sort((a, b) => {
            if (sortTypeAsc) {
                return a.brand_name.localeCompare(b.brand_name);
            } else {
                return b.brand_name.localeCompare(a.brand_name);
            }
        }).map((obj) => ({
            ...obj,
            product_types: [...obj.store_brands?.store_brand_product_type_data].sort((a, b) => {
                if (sortTypeAsc) {
                    return a.product_type_name.localeCompare(b.product_type_name);
                } else {
                    return b.product_type_name.localeCompare(a.product_type_name);
                }
            }),
        }));

        setTableData(sortedData);
        setSortTypeAsc(!sortTypeAsc);
    };

    const sortDataByName = () => {
        const sortedData = [...tableData].sort((a, b) => {
            if (sortAsc) {
                return a.brand_name.localeCompare(b.brand_name);
            } else {
                return b.brand_name.localeCompare(a.brand_name);
            }
        });

        setTableData(sortedData);
        setSortAsc(!sortAsc)
    };

    useEffect(() => {
        setTableData(storeBrandList)
    }, [storeBrandList])

    return (
        <>
            {tableData?.length > 0 ? <table className='mb-5 w-100 position-relative brand_custom_table'>
                <thead className='table-thead background-blue color-black position-sticky sticky-top '>
                    {!unrecognized && <th className='text-center'>#</th>}
                    <th className='border-right p-1'>
                        <div className='d-flex' style={{ width: "190px" }}>
                            <div className=' brandName p-2  d-flex align-items-center'>
                                <span className="fw-500"> Brand Name{sortAsc ? <BsArrowDownShort onClick={sortDataByName} className="cursor-pointer" /> :
                                    <BsArrowUpShort className="cursor-pointer" onClick={sortDataByName} />}</span>
                            </div>
                        </div>
                    </th>
                    {!unrecognized && <th className='text-center'>Brand Code</th>}
                    {!unrecognized && <th className='text-center'>Action</th>}
                    {unrecognized && <th className='text-center'>   <span className="fw-500">Product Type
                        {/* {sortTypeAsc ? <BsArrowDownShort onClick={sortProductTypesByName} className="cursor-pointer" /> :
                        <BsArrowUpShort className="cursor-pointer" onClick={sortProductTypesByName} />} */}
                    </span>
                    </th>}
                    {/* <th className='text-center'>Brand Margin &#40;%&#41; </th> */}
                    {unrecognized && <th className='text-center'>Brand Landing Cost &#40;%&#41;</th>}
                    {unrecognized && <th className='text-center'>Brand Discount &#40;%&#41;</th>}
                </thead>

                <tbody className='mt-3 table-tbody' >
                    {tableData?.map((brand: any, brandIndex: number) => (
                        Array.isArray(brand?.store_brands?.store_brand_product_type_data) && (brand?.store_brands?.store_brand_product_type_data)?.map((product: any, index: number) => (
                            <tr key={`${brand?.id}_${index}`}>
                                {(index === 0 && !unrecognized) && <td className={` p-1 text-center border-right  `} style={{ backgroundColor: "#fff" }} rowSpan={!unrecognized ? 1 : brand?.store_brands?.store_brand_product_type_data?.length}>{brandIndex + 1}</td>}
                                {index === 0 ? (
                                    <td rowSpan={!unrecognized ? 1 : brand?.store_brands?.store_brand_product_type_data?.length} className={` border-right p-1 `} style={{ backgroundColor: "#fff" }}>
                                        <div className='d-flex align-items-center'>
                                            <div className={unrecognized ? 'img-cont' : 'img-cont-max'}>
                                                <CustomImages src={brand.brand_logo ? brand?.brand_logo : ""} imageHeight="0" />
                                            </div>
                                            <div className='brandName p-2'>
                                                <span className="fw-500">{brand.brand_name}</span>
                                            </div>
                                        </div>
                                    </td>

                                ) : null}
                                {(index === 0 && !unrecognized) ? (
                                    <td
                                        rowSpan={!unrecognized ? 1 : brand?.store_brands?.store_brand_product_type_data?.length}
                                        className={` border-right p-1 text-center`} style={{ backgroundColor: "#fff" }}>
                                        <div className='d-flex align-items-center justify-content-center'>
                                            <div className='brandName p-2'>
                                                <span className="fw-500 text-center">{brand.brand_code}</span>
                                            </div>
                                        </div>
                                    </td>

                                ) : null}
                                {(index === 0 && !unrecognized) ? (
                                    <td
                                        rowSpan={!unrecognized ? 1 : brand?.store_brands?.store_brand_product_type_data?.length}
                                        className='border-left ' style={{ backgroundColor: "white" }}>
                                        <div className="d-flex justify-content-center">
                                            <Button className="custom-delete-button fs-14 fw-400 " onClick={() =>
                                                hadleAction(brand?.id)
                                            }
                                            >
                                                <div className='d-flex align-items-center '>
                                                    <RiDeleteBin6Line />
                                                    <span className='ms-2 mb-0'>Delete</span>
                                                </div>
                                            </Button>
                                        </div>
                                    </td>

                                ) : null}
                                {unrecognized && <td className='text-center'>
                                    {product?.product_type?.name || product?.product_type_name}
                                </td>}

                                {unrecognized && <td className="text-center">
                                    {product?.brand_landing_cost}
                                </td>}
                                {unrecognized && <td className='text-center'>
                                    {product?.brand_discount}
                                </td>}
                            </tr>
                        ))
                    ))}
                </tbody>

            </table> : <div className='mt-4 d-flex justify-content-center dark-gray'>
                <span>There are no records to display</span>
            </div>}
        </>
    )
}

export default Index