import React, { useContext, useEffect, useState } from 'react'
import Header from '../components/common/Header/Header';
import { Navigate } from 'react-router';
import SideBar from '../components/common/Sidebar/SideBar';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import atoms from '../components/atoms';
import { StorePopup } from '../Context';
import { getDropdown } from '../constants/utils';
import service from '../api/services';
import { RxCross2 } from 'react-icons/rx';
import './style.scss'
const { Select } = atoms

const AuthLayout = (props: any) => {
    const [isShow, setIsShow] = useContext<any>(StorePopup);
    const [storeList, setStoreList] = useState([])

    if (!localStorage.getItem("access_token")) {
        return (
            <Navigate to={{ pathname: "/login", }} />
        );
    }

    const getStoreList = async () => {
        const StoreResponse = await service.store.storeList()
        setStoreList(getDropdown(StoreResponse?.data?.data, "store_name"))
    }

    const switchStoreList = async (storeId: string) => {
        try {
            const response = await service.store.switchStore(storeId)
            if (response.status === 200) {
                localStorage.setItem("store_name", response?.data?.data?.store_name)
                localStorage.setItem("store_contact_number", response?.data?.data?.store_contact_number)
                localStorage.setItem("store_code", response?.data?.data?.store_code)
                {
                    response?.data?.data?.store_location !== null ? (
                        localStorage.setItem("store_location", response?.data?.data?.store_location))
                        : localStorage.setItem("store_location", "")
                }
                setIsShow(false)
                window.location.reload()
            }
        } catch (error) {
            console.log('error', error)
        }
    }

    useEffect(() => {
        getStoreList()
    }, [])

    return (
        <React.Fragment>
            <div id="layout-wrapper">
                <Header storeList={storeList} />
                <SideBar />
                <div className="main-content">{props.children}</div>
            </div>
            {isShow && Object.keys(storeList).length > 1 &&
                <Modal className="_custom-height" isOpen={isShow} >
                    <ModalHeader className="d-flex justify-content-center border-0 position-relative" >
                        <div className="mt-4 fs-30 fw-600">Store List</div>
                        <div className="position-absolute py-1 px-2" style={{ top: '0', right: '0', backgroundColor: '#222', borderRadius: '0px 4px 0px 8px', cursor: 'pointer' }} onClick={() => setIsShow(false)}><RxCross2 style={{ color: '#fff' }} /></div>

                    </ModalHeader>
                    <ModalBody className="rounded-10" style={{ height: 'auto' }}>
                        <Select
                            // menuIsOpen={true}
                            label={"Select Store"}
                            options={storeList}
                            name={"type"}
                            className={"w-100"}
                            placeholder={"Select store "}
                            onChange={(e: any) => switchStoreList(e?.value)}
                        ></Select>
                    </ModalBody>
                </Modal>

            }
        </React.Fragment>
    )
}

export default AuthLayout