import React, { useState, useRef } from 'react'
import atoms from '../../../atoms'
import molecules from '../../../molecules'
import { InventoryAuditProps } from './interface'
import constant from '../../../../constants/constant'

const { Input, Select, CustomSearch, Button } = atoms
const { LostBarcodeDetail,ConfirmationModal } = molecules

const Index = ({ handleBarcodeSearch, updateItemCondition, barcodeData, handleChange, updateData,confirmationPopup,setConfirmationPopup }: InventoryAuditProps) => {
    const [isOpen, setIsOpen] = useState(false);
    const searchTabContainer = useRef<HTMLDivElement>(null);

    const searchCallBack = (search: string) => {
        handleBarcodeSearch(search)
    }

    return (
        <>
            <div className='row'>
                <div className='col-12 col-md-8 mt-0'>
                    <div ref={searchTabContainer}>
                        <div className=" position-relative" onClick={() => setIsOpen(!isOpen)} >
                            <CustomSearch
                                setIsOpen={setIsOpen}
                                searchCallBack={searchCallBack}
                                placeholder='Enter Barcode Number'
                                className='fs-16'
                                heading="Search"
                                buttonStyle="w-25" />
                        </div>
                    </div>
                </div>
                <div className='col-md-4'></div>
            </div>
            {barcodeData ? <>
                <div className='my-3'>
                    <LostBarcodeDetail barcodeData={barcodeData} />
                </div>
                <div style={{ marginTop: "2r em" }}>
                    <div className='mt-4'>
                        <div className='col-12'>
                            <label className={`inputs-label mb-2 fs-16 fw-600`}>Barcode Remark</label>
                            <Input
                                type={'text'}
                                name={"conditionRemark"}
                                value={updateData.conditionRemark}
                                placeholder={"Enter Description Here"}
                                onChange={(e: any) => { handleChange("conditionRemark", e.target.value) }} />
                        </div>
                    </div>
                </div>
                <div className='mx-1 mt-4'>
                    <Button isDisabled={!updateData.conditionRemark} name={"Save"} className={"w-100"} onClick={() => { setConfirmationPopup(!confirmationPopup) }} />
                </div>
            </> :
                <div className='mt-3 d-flex justify-content-center dark-gray'>
                    <span>There are no records to display</span>
                </div>}

            {confirmationPopup && <ConfirmationModal
                show={confirmationPopup}
                handleConfirmItem={() => { updateItemCondition(barcodeData?.id); setConfirmationPopup(!confirmationPopup) }}
                onHide={() => setConfirmationPopup(!confirmationPopup)}
                message={'marked barcode lost'} />}

        </>
    )
}

export default Index