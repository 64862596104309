import { BASE_URL } from '../../constants/defaultValues'
import Dictionary from '../dictionary'
import axios from 'axios'

const { brand } = Dictionary

const brandService = {
  brandList: async (paramsObj: {}) => {
    return axios.get(BASE_URL + brand?.BrandList(), { params: paramsObj })
  },

  AddBrand: async (payload: {}) => {
    return axios.post(BASE_URL + brand.AddBrand(), payload)
  },
  deleteBrand: async (id: string) => {
    return axios.delete(BASE_URL + brand.deleteBrand(id))
  },

  UnRecognizedBrandSearch: async (payload: {}) => {
    return axios.post(BASE_URL + brand.brandSearch(), payload)
  },

  
}
export default brandService
