import React, { useEffect, useState } from 'react';
import './checkbox.scss'
import { TreeNode } from './interface';
import { AllPermissions } from '../../../constants/permission';

const TreeNodeComponent: React.FC<{ node: TreeNode, selectedCodes: string[], setSelectedCodes: Function, isStatusChange?:boolean }> = ({ node, selectedCodes, setSelectedCodes, isStatusChange }) => {
    const [isChecked, setIsChecked] = useState(isStatusChange ? true : false);
    const [isOpen, setIsOpen] = useState(isStatusChange ? true : false);
    const [isCheckedBoxClick, setIsCheckedBoxClick ] = useState<boolean>(false)
    const selectedCode = selectedCodes ? selectedCodes : []
    useEffect(() => {
        if (selectedCode && selectedCode.includes(node.perm_code)) {
            setIsChecked(true);
            setIsOpen(true);
        }
    }, [selectedCode, node.perm_code]);

    const handleCheckboxChange = (code: string, children: TreeNode[]) => {
        setIsCheckedBoxClick(true)
        let updatedSelectedCodes;
        if (isChecked) {
            // Function to get all descendant codes
            const getAllDescendantCodes = (nodes: TreeNode[]) => {
                let codes: string[] = [];
                nodes.forEach(node => {
                    codes.push(node.perm_code, ...getAllDescendantCodes(node.child || []));
                });
                return codes;
            };

            // Get all descendant codes and remove them from selectedCodes
            const descendantCodes = getAllDescendantCodes(children);
            updatedSelectedCodes = selectedCode.filter((item: string) =>
                item !== code && !descendantCodes.includes(item));
        } else {
            updatedSelectedCodes = [...selectedCode, code];
        }

        setIsChecked(!isChecked);
        setIsOpen(!isOpen);
        setSelectedCodes(updatedSelectedCodes);
    };

    // const handleCheckboxChange = (code: string) => {
    //     const updatedSelectedCodes = isChecked
    //         ? selectedCode.filter((item: string) => item !== code)
    //         : [...selectedCode, code];

    //     setIsChecked(!isChecked);
    //     setIsOpen(!isOpen);
    //     setSelectedCodes(updatedSelectedCodes);
    // };

    return (
        <li className='list-unstyled permission-checkbox '>
            <label>
                <input type="checkbox" className=' me-2 my-2' checked={isChecked} onChange={() => handleCheckboxChange(node.perm_code, node.child)} />
                {node.module_name}
            </label>
            {isOpen && node.child.length > 0 && (
                <ul>
                    {node.child.map((child: any, index: number) => (
                        <TreeNodeComponent key={index} node={child} selectedCodes={selectedCodes}
                            setSelectedCodes={setSelectedCodes} isStatusChange={isCheckedBoxClick ? false  : isStatusChange}/>
                    ))}
                </ul>
            )}
        </li>
    );
};

const UserPermissionUI = ({ structuredPermission, handleCheckboxChange, defaultPermission }: any) => {
    const permission = Object?.values(AllPermissions)?.filter(permission => permission !== AllPermissions?.store_Sub_Admin_Tab)
    const [selectedCodes, setSelectedCodes] = useState<string[]>(defaultPermission?.length===0 ? permission :[]);
    const treeData: any = structuredPermission;
    useEffect(() => {
        if (defaultPermission?.length>0) {
            const extractedCodes = defaultPermission && defaultPermission.flatMap(extractPermCodes);
            setSelectedCodes(extractedCodes);
        }
        // handleCheckboxChange(selectedCodes);
    }, [defaultPermission]);

    useEffect(() => {
        if (defaultPermission?.length>0) {
            const extractedCodes = defaultPermission && defaultPermission.flatMap(extractPermCodes);
            setSelectedCodes(extractedCodes);
        }
        // handleCheckboxChange(selectedCodes);
    }, [defaultPermission]);

    useEffect(() => {
        handleCheckboxChange(selectedCodes);
    }, [selectedCodes]);


    const extractPermCodes = (permission: any) => 
        // extractPermCodes(permission)
        [
            permission.perm_code,
            ...(permission.child ? permission && permission.child.flatMap(extractPermCodes) : [])
        ];
    // }

return (
    <div id="tree">
        <ul>
            {treeData.map((node: any, index: number) => (
                <TreeNodeComponent key={index} node={node} selectedCodes={selectedCodes}
                    setSelectedCodes={setSelectedCodes}
                    isStatusChange={defaultPermission?.length>0 ? false : true}
                />
            ))}
        </ul>
    </div>
);
};

export default UserPermissionUI;
