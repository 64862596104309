import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import atoms from "../../atoms";
import molecules from "../../molecules";
import constant from "../../../constants/constant";
import PlaceHolderImage from '../../../../assets/Images/placeholderimg.png'
import { ProductDetail, MyComponentProps } from "./interface"
import { FiEdit } from "react-icons/fi";
import moment from "moment";
import { AiOutlineEye } from "react-icons/ai";
import ThreeDots from '../../../assets/Images/threeDots.svg'
import { numberWithCommas } from "../../../constants/utils"
const { Search, Button, Table, Image, Pagination, CustomImages } = atoms
const { SidePopUp, ConfirmationModal, ContactLensContainer } = molecules
const Index: React.FC<MyComponentProps> = ({ productResponse, isRecognize, setLimitPerPage, currentPage, setCurrentPage, setSearch, limit, productDetail, getDetailById, updateVerificationStatus, handleProductDelete, productType, setProductData, brandResponse, setProductType, getDropdown, handleDownload, setIsContactLensProduct, isContactLensProduct }) => {
    const navigate = useNavigate()
    const inputRef: any = useRef()
    const [showSidebarModal, setShowSidebarModal] = useState(false)
    const [showImageLarge, setShowImageLarge] = useState(false)
    const [imageUrl, setImageUrl] = useState()
    const [imageIndex, setImageIndex] = useState<any>()
    const [deletePopup, setDeletePopup] = useState<boolean>(false)
    const [subAdminId, setSubAdminId] = useState("")
    const [clickedBrandCategory, setClickedBrandCategory] = useState<any>()

    // useEffect(() => {
    //     if (productResponse?.data) {
    //         const result = productResponse?.data?.filter((items: any) => items?.verification_status === constant.VERIFICATION_STATUS.VERIFIED);
    //         setTableData(result)
    //     }
    // }, [productResponse])
    const columns = [
        { name: 'Brand', selector: (row: ProductDetail) => row.brand.brand_name, sortable: true, minWidth: '15%' },
        { name: 'Date', selector: (row: any) => row?.createdAt ? moment(row?.createdAt).format('DD/MM/YYYY') : "-", },
        { name: 'Product ID', selector: (row: ProductDetail) => `${row.product_id_display}`, minWidth: '15%' },
        { name: 'Cover Photo', cell: (row: any) => <CustomImages src={row?.images?.length > 0 && row?.images[0]} imageHeight={'0px'} />, center: true },
        { name: 'Model No.', selector: (row: ProductDetail) => `${row.modal_number}` },
        { name: 'Product Type', selector: (row: ProductDetail) => row.product_type.name },
        { name: 'Product SRP (₹)', selector: (row: ProductDetail) => `${row.product_srp !== null ? `₹${numberWithCommas(row.product_srp)}` : '-'}`, },
        { name: 'Status', selector: (row: ProductDetail) => row.isActive },
        { name: 'Verification Status', selector: (row: ProductDetail) => row.verification_status },
        { name: 'Action', selector: (row: any) => row.action, },
    ]

    const columnsForContactLens = [
        { name: 'Brand', selector: (row: ProductDetail) => row.brand.brand_name, sortable: true, minWidth: '15%' },
        { name: 'Date', selector: (row: any) => row?.createdAt ? moment(row?.createdAt).format('DD/MM/YYYY') : "-", },
        { name: 'Product ID', selector: (row: ProductDetail) => `${row.product_id_display}`, minWidth: '15%' },
        { name: 'Cover Photo', cell: (row: any) => <CustomImages src={row?.images?.length > 0 && row?.images[0]} imageHeight={'0px'} />, center: true },
        { name: 'Product Type', selector: (row: ProductDetail) => row.product_type.name },
        { name: 'Product SRP (₹)', selector: (row: ProductDetail) => `${row.product_srp !== null ? `₹${numberWithCommas(row.product_srp)}` : '-'}`, },
        { name: 'Status', selector: (row: ProductDetail) => row.isActive },
        { name: 'Verification Status', selector: (row: ProductDetail) => row.verification_status },
        { name: 'Action', selector: (row: any) => row.action, },
    ]

    const showImage = (imageUrl: any, index: any) => {
        setShowImageLarge((prevState) => !prevState)
        setImageUrl(imageUrl)
        setImageIndex(index)
    }

    const closeImage = () => {
        setShowImageLarge((prevState) => !prevState)
    }

    const handleChange = (name: string, value: any) => {
        setProductData((prevState: any) => ({
            ...prevState,
            [name]: value
        }));

    };

    // const handleBrand = (value: any) => {
    //     if (brandResponse && value) {
    //         setProductData((prev: any) => ({ ...prev, product_type_id: "" }));
    //         const filteredData: any = brandResponse?.filter((item: any) => item?.id === value?.value);
    //         setProductType(getDropdown(filteredData[0]?.product_types))
    //     }
    // }

    const handelDeleteButton = (id: string) => {
        setSubAdminId(id)
        setDeletePopup(!deletePopup)
    }

    useEffect(() => {
        if (inputRef?.current) {
            inputRef.current.value = "";
        }
    }, [isRecognize, isContactLensProduct]);
    const handleLensTypeChange = (value: boolean) => {
        setCurrentPage(1);
        setSearch('')
        setIsContactLensProduct(value)
        //     setProductData((prevState: any) => ({
        //       ...prevState,
        //       brand_id: [],
        //   }));
    }
    return (
        <>
            <div className="row">
                <div className="col-12 col-md-4 mb-lg-0 mb-3">
                    <Search
                        refs={inputRef}
                        searchCallBack={(e: string) => {
                            setCurrentPage(1)
                            // setIsContactLensProduct(false)
                            setSearch(e);
                        }}
                        className="w-100"
                    />
                </div>
                <div className="col-12 col-md-8 gap-2 d-lg-flex justify-content-lg-end align-items-center px-lg-0">
                    <div>
                        <ContactLensContainer
                            value={isContactLensProduct}
                            onStatusChange={(value: boolean) => handleLensTypeChange(value)}
                        />
                    </div>
                    <div className="d-flex justify-content-lg-center justify-content-end">
                        <Button name={"Add Product"}
                            style={{ minWidth: "140px", maxHeight: "45px" }}
                            onClick={() => navigate(`/product/add?isRecognize=${isRecognize ? constant.BRAND_CATEGORY[0].value : constant.BRAND_CATEGORY[1].value}`)}
                        />
                    </div>
                </div>
            </div>
            <div >

                <Table columns={isContactLensProduct ? columnsForContactLens : columns}
                    AdditionalClassName="data-table-container"
                    data={productResponse?.data?.map((item: ProductDetail, index: number) => {
                        return ({
                            ...item,
                            isActive: item.isActive ? (
                                <div className="background-light-green border-product-radius">
                                    <p className="color-green m-1 p-1">{constant.STATUS.ACTIVE}</p>
                                </div>) : <div className="background-light-red border-product-radius">
                                <p className="color-red m-1 p-1">{constant.STATUS.DEACTIVE}</p>
                            </div>,
                            verification_status: item.verification_status === constant.VERIFICATION_STATUS.PENDING ? (
                                <div className="background-light-yellow border-product-radius"><p className="color-yellow m-1 p-1">{'Pending'}</p></div>) : item.verification_status === constant.VERIFICATION_STATUS.VERIFIED ?
                                (<div className="background-light-green border-product-radius"><p className="color-green m-1 p-1">{"Verified"}</p></div>) : item.verification_status === constant.VERIFICATION_STATUS.REWORK ? (<div className="background-light-red border-product-radius"><p className="color-red m-1 p-1">{"Rejected"}</p></div>) : ''
                            ,
                            action:
                                <> <div className='table-data-width'>
                                    <button
                                        className="btn dropdown-dots shadow-none action-button"
                                        type="button"
                                        id="dropdownMenuButton1"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                    >
                                        <Image src={ThreeDots} />
                                    </button>
                                    <ul
                                        className="dropdown-menu dropdown-menu-end dropdown-style"
                                        aria-labelledby="dropdownMenuButton1"
                                    >
                                        <li>
                                            {/* {item.verification_status === constant.VERIFICATION_STATUS.VERIFIED ? ( */}
                                            <button
                                                className="dropdown-item dropdown-item-size"
                                                onClick={() => {
                                                    getDetailById(item.id);
                                                    setClickedBrandCategory(item?.brand?.brand_category)
                                                    setShowSidebarModal(true);
                                                }}
                                            >
                                                <div className='d-flex align-items-center'>
                                                    <AiOutlineEye />
                                                    <span className='ms-2 mb-0'>View</span>
                                                </div>
                                            </button>
                                        </li>

                                        {/* ) : ( */}
                                        {!isRecognize &&
                                            <>
                                                <li>
                                                    <hr className="my-1"></hr>
                                                </li>
                                                <li>
                                                    <button
                                                        className="dropdown-item dropdown-item-size"
                                                        onClick={() => navigate(`/product/edit/${item.id}?isRecognize=${isRecognize ? constant.BRAND_CATEGORY[0].value : constant.BRAND_CATEGORY[1].value}`)}
                                                    >
                                                        <div className='d-flex align-items-center'>
                                                            <FiEdit />
                                                            <span className='ms-2 mb-0'>Edit</span>
                                                        </div>
                                                    </button>

                                                </li>
                                            </>
                                        }

                                        {/* ) : ( */}
                                        {
                                            <>
                                                <li>
                                                    <hr className="my-1"></hr>
                                                </li>
                                                <li>
                                                    <button
                                                        className="dropdown-item dropdown-item-size"
                                                        onClick={() =>
                                                            navigate(
                                                                `/product/edit/${item.id
                                                                }?isDuplicate=${true}&isRecognize=${isRecognize ? constant.BRAND_CATEGORY[0].value : constant.BRAND_CATEGORY[1].value}`
                                                            )
                                                        }
                                                    >
                                                        <div className="d-flex align-items-center">
                                                            <FiEdit />
                                                            <span className="ms-2 mb-0">Duplicate</span>
                                                        </div>
                                                    </button>
                                                    {/* )} */}
                                                </li>
                                            </>
                                        }

                                        {/* ) : ( */}
                                        {/* <li>
                                            <hr className="my-1"></hr>
                                        </li>
                                        <li>
                                            <button
                                                className="dropdown-item dropdown-item-size"
                                                onClick={() => handelDeleteButton(item?.id)}
                                            >
                                                <div className='d-flex align-items-center text-danger'>
                                                    <RiDeleteBin6Line />
                                                    <span className='ms-2 mb-0'>Delete</span>
                                                </div>
                                            </button>
                                         
                                        </li> */}

                                    </ul>
                                </div></>
                        })
                    })}
                />
            </div>
            <div>
                <Pagination
                    totalCount={productResponse?.count}
                    currentPage={currentPage}
                    totalPages={productResponse?.totalPages}
                    onChangePage={(page: any) => setCurrentPage(page)}
                    setLimit={(e: number) => { setCurrentPage(1); setLimitPerPage(e) }}
                    limit={limit}
                />
            </div>
            {showSidebarModal && <div>
                <SidePopUp show={showSidebarModal}
                    onHide={() => setShowSidebarModal(false)}
                    productDetail={productDetail}
                    showImage={(imageUrl: any, index: any) => showImage(imageUrl, index)}
                    updateVerificationStatus={(id: string, status: number) => updateVerificationStatus(id, status)}
                    isBrandedCategory={clickedBrandCategory === constant.BRAND_CATEGORY[0].value}
                />
            </div>}
            {/* {showImageLarge &&
                <ImagePopup imageUrlData={imageUrl} handleClose={closeImage} Index={imageIndex} />

            } */}
            {deletePopup && <ConfirmationModal
                show={deletePopup}
                handleConfirmItem={() => { handleProductDelete(subAdminId); setDeletePopup(!deletePopup) }}
                onHide={() => setDeletePopup(!deletePopup)}
                message={'delete this product'} />}
        </>
    )
}
export default Index