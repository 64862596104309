import { BASE_URL } from '../../constants/defaultValues'
import Dictionary from '../dictionary'
import axios from 'axios'

const { barcode } = Dictionary

const BarcodeService = {
    view: async (payload:{}) => {
        return axios.post(BASE_URL + barcode?.view(), payload)
    }, 
    statusVerificationService: async (payload:{}) => {
        return axios.put(BASE_URL + barcode?.statusVerificationService(), payload)
    },
    statusChangeService: async (id:string)=>{
        return axios.put(BASE_URL + barcode.barcodeStatusById(id));
    },

    getLostBarcode: async (paramsObj: {}) => {
        return axios.get(BASE_URL + barcode.getLostBarcode(), { params: paramsObj })
    },
    searchLostBarcode: async (paramsObj: {}) => {
        return axios.get(BASE_URL + barcode.searchLostBarcode(), { params: paramsObj })
    },
    markBarcodeLost: async (payload: any) => {
        return axios.post(BASE_URL + barcode.markBarcodeLost(), payload)
    },
}
export default BarcodeService
