import React, { useState,useEffect,useContext } from 'react'
import Wrapper from '../../../components/common/Wrapper/Wrapper'
import organisms from '../../../components/organisms'
import molecules from '../../../components/molecules'
import { useToasts } from 'react-toast-notifications';
import constant from '../../../constants/constant';
import service from '../../../api/services'
import { findConstantLabel } from '../../../constants/utils';

const { InventoryAudit } = organisms
const { TopBar } = molecules


const Index = () => {
    const [barcodeData, setBarcodeData] = useState<any>()
    const [updateData, setUpdateData] = useState<any>({
        item_condition: [],
        conditionRemark: "",
    })
    const { addToast } = useToasts();

    const getDropdown = (response:any, name: string) => {
        let dropdown = {
            "key": response.id,
            "value": response[name],
            "label": findConstantLabel(constant.INVENTORY_ITEM_CONDITION, response[name]),
        }
        return dropdown
    }

    const handleBarcodeSearch = async (search: string) => {
        // try {
        //     const response = await service.Audit.barcodeSearchApi({ search: search })
        //     if (response.status === 200) {
        //         setBarcodeData(response?.data?.data)
        //         setUpdateData({ ...updateData, item_condition: getDropdown(response.data.data, "item_condition"), conditionRemark: response.data.data.condition_remark })
        //     }
        // } catch (error: any) {
        //     addToast(error?.response?.data?.message, {
        //         appearance: 'error',
        //         autoDismiss: true,
        //         autoDismissTimeout: 3000,
        //     });

        // }
    }

    const updateItemCondition = async (id: string) => {
        // const payload = {
        //     "item_condition": updateData.item_condition.value,
        //     "condition_remark": updateData.conditionRemark
        // }
        // try {
        //     const response = await service.Audit.updateItemCondition(id, payload)
        //     if (response.status === 200) {
        //         addToast(response?.data?.message, {
        //             appearance: 'success',
        //             autoDismiss: true,
        //             autoDismissTimeout: 3000,
        //         });
        //     }
        // } catch (error: any) {
        //     console.log('error', error)
        //     addToast(error?.response?.data?.message, {
        //         appearance: 'error',
        //         autoDismiss: true,
        //         autoDismissTimeout: 3000,
        //     });
        // }
    }

    const handleChange = (name: string, value: any) => {
        setUpdateData({ ...updateData, [name]: value })
    }

    return (
        <Wrapper>
            <div className=''>
                <div className='row align-items-center'>
                    <TopBar heading={'Inventory Audit'} />
                </div>
                <InventoryAudit
                    handleBarcodeSearch={(search: string) => handleBarcodeSearch(search)}
                    updateItemCondition={updateItemCondition}
                    barcodeData={barcodeData}
                    handleChange={handleChange}
                    updateData={updateData}
                />
            </div>
        </Wrapper>
    )
}

export default Index