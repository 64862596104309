import React, { useEffect, useState, useRef, useContext } from 'react'
import Wrapper from '../../../../components/common/Wrapper/Wrapper';
import atoms from '../../../../components/atoms';
import molecules from '../../../../components/molecules';
import FilterImage from '../../../../assets/Images/filter.svg'
import organisms from '../../../../components/organisms';
import service from '../../../../api/services';
import InfiniteScroll from 'react-infinite-scroll-component'
import { RxCross2 } from 'react-icons/rx';
import { HeaderHeading } from '../../../../Context';
import { FiSearch } from "react-icons/fi";
import { MdFilterAlt } from "react-icons/md";
import { MdFilterAltOff } from "react-icons/md";
import { SelectedCheckboxes } from "../../../../Context"
import constant from '../../../../constants/constant';
import { TbArrowsSort } from "react-icons/tb";

const { ProductList } = organisms
const { TopBar, Filter } = molecules
const { Search, Button, Image } = atoms
interface Checkbox {
  id: string;
  name: string;
}

interface SelectedCheckboxes {
  [key: string]: Checkbox[];
}
const Index = () => {
  const limit = 20
  const controlledRef = useRef<AbortController>();
  const [isLoading, setIsLoading] = useState<number>(0)
  const [productList, setProductList] = useState<string[]>([])
  const [filterList, setFilterList] = useState([])
  const [search, setSearch] = useState<string>('')
  const [range, setRange] = React.useState([0, 50000]);
  const [count, setCount] = useState(1);
  const [totalPage, setTotalPage] = useState<number>(1)
  const [totalCount, setTotalCount] = useState<number>(0)
  const { setHeader }: any = useContext(HeaderHeading);
  const [showFilter, setShowFilter] = useState(window.innerWidth < 768 ? false : true)
  const [showSearchTab, setShowSearchTab] = useState<boolean>(window.innerWidth < 768 ? false : true)
  const [isPictures, setIsPictures] = useState<boolean>(false)
  const [sortOrder, setSortOrder] = useState<number>(constant?.Product_List_Price_Sort?.Low_To_High)
  const { selectedCheckboxes, setSelectedCheckboxes }: any = useContext(SelectedCheckboxes)

  const [allLabels, setAllLabels] = useState<string[]>([]);
  const scrollContainerRef = useRef(null);

  const getFilterList = async () => {
    try {
      const response = await service.product.filter()
      if (response.status === 200) {
        setFilterList(response.data.data)
      }
    } catch (error) {
      console.log('error', error)
    }
  }

  useEffect(() => {
    setHeader("Procurement - Products")
    getFilterList()
  }, [])

  const getProductList = async () => {
    setIsLoading(prevCount => prevCount + 1)

    if (controlledRef.current) {
      controlledRef.current.abort()
    }

    controlledRef.current = new AbortController();

    const signal = controlledRef.current.signal;

    let priceRange = {
      min: range[0],
      max: range[1]
    }
    let convertedCheckboxes: SelectedCheckboxes = {};
    for (let key in selectedCheckboxes) {
      convertedCheckboxes[key] = selectedCheckboxes[key].map((item: any) => item.id);
    }
    let requestPayload:any ={
      search: search,
      brand: (convertedCheckboxes.brand),
      type: (convertedCheckboxes.type),
      polarized: (convertedCheckboxes.polarized),
      frame_materials: (convertedCheckboxes.frame_materials),
      product_shapes: (convertedCheckboxes.product_shapes),
      rim_type: (convertedCheckboxes.rim_type),
      features: (convertedCheckboxes.features),
      gender: (convertedCheckboxes.gender),
      price: (priceRange),
      sw_color: (convertedCheckboxes.sw_color_list),
      lens_color: (convertedCheckboxes.lens_color),
      bridge_size: (convertedCheckboxes.bridge_size),
      weight: (convertedCheckboxes.weight),
      glass_size: (convertedCheckboxes.glass_size),
      temple_size: (convertedCheckboxes.temple_size),
      period_use: (convertedCheckboxes.period_use),
      pack_of_qty: (convertedCheckboxes.pack_of_qty),
      limit: limit,
      page: count
    }
    if (isPictures) {
      requestPayload.pictures = isPictures;
    }
    if (sortOrder === constant?.Product_List_Price_Sort.High_To_Low) {
      requestPayload.sort_order = sortOrder;
    }
    try {
      const response = await service.product.list(requestPayload, signal)
      if (response.status === 200) {
        setTotalCount(response.data.total_count)
        setTotalPage(response?.data?.totalPages)
        if (count > 1) {
          const combinedArray = [...productList, ...response.data.data];
          const uniqueIds = new Set<number>();
          // Filter the combined array to include only unique entries based on ID
          const uniqueArray = combinedArray?.filter((item) => {
            if (!uniqueIds.has(item.id)) {
              uniqueIds.add(item.id);
              return true;
            }
            return false;
          });
          // Update the state with the unique array
          setProductList(uniqueArray);
          setIsLoading(prevCount => prevCount - 1)
        } else {
          setProductList(response.data.data)
          setIsLoading(prevCount => prevCount - 1)
        }

      }
    } catch (error) {
      console.log('error', error)
      setIsLoading(prevCount => prevCount - 1)
    }
  }

  const handlePriceRange = (event: any, newValue: any) => {
    setRange(newValue);
    if (!newValue) {
      setRange([0, 50000])
    }
  }

  const handleInputPrice = (e: any) => {
    if (e.target.name == "minPrice") {
      setRange([e.target.value, range[1]])
    }
    else {
      setRange([range[0], e.target.value])
    }
  }
  const _handleSearch = (e: string) => {
    setSearch(e)
    setCount(1)
  }

  useEffect(() => {
    getProductList()
  }, [count, search, range])


  const handleScroll = () => {
    const element: any = scrollContainerRef.current;
    let debounce: any;
    if (
      (element.scrollTop + element.clientHeight >= (element.scrollHeight - 10))
    ) {
      if (productList?.length != totalCount) {
        if (totalPage >= count) {
          clearTimeout(debounce)
          debounce = setTimeout(() => {
            setCount(count + 1)
          }, 500);
        }
      }
    }
  };

  const _handleFilterChange = () => {
    const isAnyCheckboxSelected = Object.values(selectedCheckboxes).some(
      (checkboxArray: any) => checkboxArray.length > 0
    );
    if (isAnyCheckboxSelected) {
      setCount(1);
      window.scrollTo(0, 0);
    }
    getProductList()
  }
  useEffect(() => {
    _handleFilterChange()

    // Create an array to store labels
    const labels: string[] = [];

    // Iterate through each property
    for (const prop in selectedCheckboxes) {
      // Check if the property is an array
      if (Array.isArray(selectedCheckboxes[prop])) {
        // Iterate through the array and add labels to the array
        selectedCheckboxes[prop].forEach((item: any) => {
          labels.push(item);
        });
      }
    }

    // Update the state with the labels
    setAllLabels(labels);
  }, [selectedCheckboxes,isPictures,sortOrder]);

  const handleBadgeRemove = (badgeToRemove: string) => {
    // Create a copy of the selectedCheckboxes
    const updatedCheckboxes = { ...selectedCheckboxes };

    // Iterate through each property and remove the label from the corresponding array
    for (const prop in updatedCheckboxes) {
      if (Array.isArray(updatedCheckboxes[prop])) {
        updatedCheckboxes[prop] = updatedCheckboxes[prop].filter((item: any) => item !== badgeToRemove);
      }
    }
    // Update the state with the modified selectedCheckboxes
    setSelectedCheckboxes(updatedCheckboxes);
  };



  return (
    <Wrapper className="p-md-3 px-3 mt-lg-3 overflow-hidden">
      <div className='row align-items-center'>
        <div className='col-lg-3'>
          <TopBar heading={'Product List'}>
            <div className='d-flex gap-3 justify-content-between top-bar-width'>
              <div className='my-auto' style={{minWidth:"56px"}}>
                {productList?.length} / {totalCount}
              </div>
              <div className='d-lg-none ms-3 ms-sm-5 d-flex gap-4'>
                <div className='my-auto d-lg-none'>
                  <button
                    className='btn pe-0 me-0'
                    style={{ borderColor: "white" }}
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false" >
                    <div className='d-flex gap-1 align-item-center my-auto'>
                      <TbArrowsSort className='fs-18 my-auto' />
                    </div>
                  </button>
                  <ul
                    className="dropdown-menu zindex-tooltip"
                    aria-labelledby="dropdownMenuButton1"
                    style={{ width: "175px" }}
                  >
                    {<li>
                      <button
                        className={`dropdown-item ${(constant?.Product_List_Price_Sort?.Low_To_High === sortOrder) && 'product-list-sort-active'}`} >
                        <div className='d-flex align-items-center'
                          onClick={() => (setSortOrder(constant?.Product_List_Price_Sort?.Low_To_High))}
                        >

                          <span className='ms-2 mb-0'>Price: Low to High</span>
                        </div>
                      </button>
                    </li>}


                    {<li>
                      <hr className="my-1"></hr>
                    </li>}
                    <li>
                      <button
                        className={`dropdown-item ${(constant?.Product_List_Price_Sort?.High_To_Low === sortOrder) && 'product-list-sort-active'}`}
                        onClick={() => (setSortOrder(constant?.Product_List_Price_Sort?.High_To_Low))}
                      >
                        <div className={'d-flex align-items-center'}>

                          <span className='ms-2 mb-0'>Price:High to Low</span>
                        </div>
                      </button>

                    </li>
                  </ul>
                </div>
                <div>
                  <FiSearch className='fs-18' onClick={() => setShowSearchTab(!showSearchTab)} />
                </div>
                <div>
                  {!showFilter ? <MdFilterAlt className='fs-18' onClick={() => setShowFilter(!showFilter)} /> : <MdFilterAltOff className='fs-18' onClick={() => setShowFilter(!showFilter)} />}
                </div>
              </div>
            </div>
          </TopBar>
        </div>

        {/* {showSearchTab && <div className='col-lg-9 mt-lg-3 mt-2'>
          <Search className='search search-style' placeholder='Search for Model No, Product ID'
            searchCallBack={(e: string) => _handleSearch(e)} />
        </div>} */}
        {showSearchTab &&
          <div className='col-12 col-lg-9 mt-3 mt-lg-0'>
            <div className='row ps-0'>
              <div className='col-lg-11'>
                <Search className='search search-style' placeholder='Search for Model No, Product ID'
                  searchCallBack={(e: any) => _handleSearch(e)}
                />
              </div>
              <div className='col-lg-1 px-0 d-none d-lg-flex' >
                <button
                  className='outline-button-filter d-flex gap-1 px-1 justify-content-center'
                  style={{
                    width: "84%", height: "44px",
                    color: (constant?.Product_List_Price_Sort?.High_To_Low === sortOrder) ? '#05B7E4' : '#505d6f',
                    borderColor: (constant?.Product_List_Price_Sort?.High_To_Low === sortOrder) ? '#05B7E4' : '#505d6f',
                  }}
                  id="dropdownSortButton"
                  data-bs-toggle="dropdown"
                  aria-expanded="false" >
                  <div className='d-flex gap-1 align-item-center my-auto'>
                    <TbArrowsSort className='fs-18 my-auto' />
                    <span className='fs-16 fw-500'>Sort </span>
                  </div>
                </button>
                <ul
                  className="dropdown-menu zindex-tooltip"
                  aria-labelledby="dropdownSortButton"
                  style={{ width: "175px" }}
                >
                  {<li>
                    <button
                      className={`dropdown-item ${(constant?.Product_List_Price_Sort?.Low_To_High === sortOrder) && 'product-list-sort-active'}`} >
                      <div className='d-flex align-items-center'
                        onClick={() => setSortOrder(constant?.Product_List_Price_Sort?.Low_To_High)}
                      >

                        <span className='ms-2 mb-0'>Price: Low to High</span>
                      </div>
                    </button>
                  </li>}


                  {<li>
                    <hr className="my-1"></hr>
                  </li>}
                  <li>
                    <button
                      className={`dropdown-item ${(constant?.Product_List_Price_Sort?.High_To_Low === sortOrder) && 'product-list-sort-active'}`}
                      onClick={() => (setSortOrder(constant?.Product_List_Price_Sort?.High_To_Low))}
                    >
                      <div className={'d-flex align-items-center'}>

                        <span className='ms-2 mb-0'>Price:High to Low</span>
                      </div>
                    </button>

                  </li>
                </ul>
              </div>
            </div>

          </div>}
        {/* <div className='col-3 mt-md-0 mt-3 p-0' style={{ display: window.innerWidth < 768 ? "" : "none" }}>
          <Button className='outline-button-filter' onClick={() => setShowFilter(!showFilter)}> <Image src={FilterImage} alt="" /> Filter</Button>
        </div> */}
      </div>
      <div className='row'>
        {showFilter && <div className="col-lg-3">
          <Filter filterList={filterList}
            setSelectedCheckboxes={setSelectedCheckboxes}
            selectedCheckboxes={selectedCheckboxes}
            range={range}
            handlePriceRange={handlePriceRange}
            handleInputPrice={handleInputPrice}
            setShowFilter={() => setShowFilter(!showFilter)}
            setIsPictures={(value: boolean) => { setIsPictures(value); setCount(1) }}
            isPictures={isPictures}
            sortOrder={sortOrder}
            setSortOrder={(sortValue: number) => setSortOrder(sortValue)} />
        </div>}
        <div className="col-lg-9 col-fixed mt-lg-3">
          <div className="mt-2 mt-lg-0 mb-lg-2">
            {allLabels && allLabels.map((item: any) => {
              return (
                <div className="badge bg-secondary fw-500 mx-1" > {item.label}
                  <span className='px-2 cursor-pointer' onClick={() => handleBadgeRemove(item)}> <RxCross2 />
                  </span>
                </div>
              )
            })}
          </div>
          {productList?.length > 0 ? <div className='product-list-scroll' ref={scrollContainerRef} onScroll={handleScroll}>
            <InfiniteScroll
              dataLength={productList?.length}
              next={() => { <></> }}
              hasMore={true}
              loader={<></>}
            >
              <ProductList productList={productList} />
            </InfiniteScroll>
          </div> : <div className='fw-400 text-center dark-gray mt-4'><p>No Product Available</p></div>}
        </div>
      </div >

      {(isLoading > 0) &&
        <div className="product-list-loader">
          <div className="spinner-border text-primary loading-Two" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      }
    </Wrapper >
  )
}

export default Index