import React, { useState, useEffect, useContext } from 'react';
import Wrapper from '../../../../components/common/Wrapper/Wrapper';
import organisms from '../../../../components/organisms';
import service from '../../../../api/services';
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useToasts } from 'react-toast-notifications';
import { convertArrayToKeyValue, getDropdownValue, getParams } from '../../../../constants/utils';
import { HeaderHeading } from '../../../../Context';
import constant from '../../../../constants/constant';
import { Product } from './interface';

const { AddProduct } = organisms

const Index = () => {
    const navigate = useNavigate()
    const { addToast } = useToasts();
    const [typeValue, setTypeValue] = useState<any>(getParams('isRecognize'))
    const { setHeader }: any = useContext(HeaderHeading);
    const [productType, setProductType] = useState([])
    const [brand, setBrand] = useState([])
    const [brandResponse, setBrandResponse] = useState([])
    const [productShape, setProductShape] = useState([])
    const [previewImageUrl, setPreviewImageUrl] = useState([])
    const [productMaterial, setProductMaterial] = useState([])
    const [productColor, setProductColor] = useState([])
    const [glassSizes, setGlassSizes] = useState<any>([])
    const [power, setPower] = useState<any>([])
    const [templeSizes, setTempleSizes] = useState<any>([])
    const [bridgeSizes, setBridgeSizes] = useState<any>([])
    const [isUnBranded, setIsUnBranded] = useState(false)
    const [isContactLensProduct, setIsContactLensProduct] = useState<any>(false)
    const [apiInprogress, setApiInprogress] = useState<boolean>(false)

    let params = useParams();
    let productEditID = params.id || ''
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const isDuplicateValue = searchParams.get('isDuplicate');
    const [productData, setProductData] = useState<any>({
        isActive: false,
        product_id_display: '',
        modal_number: "",
        brand_color_code: '',
        brand_color_name: '',
        specx_colors: [],
        lens_colors: [],
        glass_size: '',
        temple_size: '',
        bridge_size: '',
        product_with_case: false,
        description: '',
        product_srp: '',
        features: [],
        rim_type: '',
        gender: [],
        warranty: '',
        weight: '',
        product_type_id: '',
        brand_id: '',
        product_materials: [],
        shapes: [],
        images: [],
        cover_index: 0,
        isPolarised: '',
        productDescription: "",
        lens_type: '',
        lens_name: '',
        period_use: '',
        pack_of_quantity: 0,
        color_id: '',

    })

    const getProductData = async () => {
        try {
            let productShapeResponse = await service.product.productShapeListingService({})
            let productMaterialResponse = await service.product.productMaterialListingService({})
            let productColorResponse = await service.product.productColorListingService({})
            let brandResponse = await service.product.brandListingService({ brand_category: typeValue })
            let sidesResponse = await service.product.sizeDetails()

            setGlassSizes(convertArrayToKeyValue(sidesResponse?.data.data.glass_size))
            setPower(convertArrayToKeyValue(sidesResponse?.data.data.powers))
            setTempleSizes(convertArrayToKeyValue(sidesResponse?.data.data.temple_size))
            setBridgeSizes(convertArrayToKeyValue(sidesResponse?.data.data.bridge_size))
            setBrandResponse(brandResponse?.data?.data)
            setBrand(getDropdown(brandResponse?.data?.data, "id", "name"))
            setProductShape(getDropdown(productShapeResponse?.data?.data, "id", "name"))
            setProductMaterial(getDropdown(productMaterialResponse?.data?.data, "id", "name"))
            setProductColor(getDropdown(productColorResponse?.data?.data, "id", "name"))
            if (productEditID?.length > 0) {

                getEditData(getDropdown(brandResponse.data.data, "id", "name"),
                    brandResponse.data.data,
                    getDropdown(productShapeResponse?.data?.data, "id", "name"),getDropdown(productColorResponse?.data?.data, "id", "name"))
            }
        } catch (error) {
            console.error("error", error)
        }
    }
    const getDropdown = (response: [], key: string, name: string) => {
        let dropdown: any = []
        response && response.map((value: any) => {
            dropdown.push({
                "value": value[key] || value.product_type.id,
                "label": value[name] || value.brand_name || value.color_name || value.product_type.name,
                "code": value?.brand_code || value?.product_type_initials || value?.product_type?.product_type_initials,
                "brand_category": value?.brand_category
            }
            )
        })
        return dropdown
    }
    const getDropdownData = (response: any, constant_key: any) => {
        let tempArr: any = []
        response && response?.map((data: any) => {
            constant_key.map((item: any) => {
                if (data === item.value) {
                    tempArr?.push(item)
                }
            })
        })
        return tempArr
    }
    const addProducts = async () => {
        setApiInprogress(true)
        const formdata: any = new FormData();
        let singleKey: string[] = ['rim_type', 'warranty', 'weight', 'product_type_id',
            'brand_id', 'isPolarised', 'glass_size', 'temple_size', 'bridge_size', 'lens_type', 'period_use', 'color_id']
        let multiKey: string[] = ['product_materials', 'features', 'specx_colors', 'lens_colors',
            'shapes', 'gender',]
        for (const key in productData) {
            if (productData.hasOwnProperty(key) && productData[key] !== undefined &&
                productData[key] !== '' && productData[key] !== null) {
                if (singleKey.includes(key)) {
                    formdata.append(key, productData[key].value)
                } else if (multiKey.includes(key)) {
                    productData[key].forEach((item: any) => formdata.append(key, item.value));
                } else if (key === 'images') {
                    productData?.images.forEach((image: any) => formdata.append('product_images', image))
                } else {
                    formdata.append(key, productData[key]);
                }
            }
        }
        formdata.append('is_contact_lens', isContactLensProduct)
        try {
            if (isDuplicateValue) {
                let response = await service.product.add(formdata, { "Content-type": "multipart/form-data" })
                if (response.status === 201) {
                    addToast('Product Duplicate successfully', {
                        appearance: 'success',
                        autoDismiss: true,
                        autoDismissTimeout: 3000,
                    });
                    // navigate(-1)
                    navigate(`/pending-product?pending_product_type=${typeValue}`)
                }
            } else {
                if (productEditID) {
                    let response = await service.product.edit(productEditID, formdata, { "Content-type": "multipart/form-data" })
                    if (response.status === 200) {
                        addToast('Product updated successfully', {
                            appearance: 'success',
                            autoDismiss: true,
                            autoDismissTimeout: 3000,
                        });
                        localStorage.setItem("isContactLensProduct", JSON.stringify(isContactLensProduct));
                        navigate(-1)
                    }
                } else {
                    let response = await service.product.add(formdata, { "Content-type": "multipart/form-data" })
                    if (response.status === 201) {
                        addToast('Product Add successfully', {
                            appearance: 'success',
                            autoDismiss: true,
                            autoDismissTimeout: 3000,
                        });
                        localStorage.setItem("isContactLensProduct", JSON.stringify(isContactLensProduct));
                        navigate(`/pending-product?pending_product_type=${typeValue}`)
                        // navigate(-1)
                        
                    }
                }
            }
        } catch (error: any) {
            setApiInprogress(false)
            console.log(error)
            addToast(error.response.data.message, {
                appearance: 'error',
                autoDismiss: true,
                autoDismissTimeout: 3000,
            });
        }
    }


    useEffect(() => {
        setHeader("Add Product")
        getProductData()
    }, [])


    const getEditData = async (brand: any, brandResponseData: any, shape: any,colors:any) => {
        let productData
        try {
            let response = await service.product.getDetailById(productEditID)
            if (response?.data?.data?.brand?.id && brandResponse) {
                const filteredData: any = brandResponseData.filter((item: any) => item?.id === response?.data?.data?.brand?.id);
                productData = getDropdown(filteredData[0]?.product_types, "product_type.id", "name")

                setProductType(getDropdown(filteredData[0]?.product_types, "product_type.id", "name"))
            }
            setIsContactLensProduct(response?.data?.data?.is_contact_lens)
            handleEditData(response, brand, productData, shape,colors)

        } catch (error) {
            console.log("error: ", error)
        }
    }

    const handleEditData = (response: any, brandData: any, type: any, shape: any,colors:any) => {
        setPreviewImageUrl(response.data.data.images)
       
        let editProductData: Product = {
            isActive: response?.data?.data?.isActive,
            product_id_display: response?.data?.data?.product_id_display,
            modal_number: response?.data?.data?.modal_number,
            brand_color_code: response?.data?.data?.brand_color_code,
            brand_color_name: response?.data?.data?.brand_color_name,
            pack_of_quantity: response?.data?.data?.pack_of_quantity,
            
            lens_name: response?.data?.data?.lens_name,
            glass_size: {
                "value": response?.data?.data?.glass_size,
                "label": response?.data?.data?.glass_size,
            },
            temple_size: {
                "value": response?.data?.data?.temple_size || '',
                "label": response?.data?.data?.temple_size || '',
            },
            bridge_size: {
                "value": response?.data?.data?.bridge_size || '',
                "label": response?.data?.data?.bridge_size || '',
            },
            product_srp: response?.data?.data?.product_srp,
            product_with_case: response?.data?.data?.product_with_case,
            productDescription: response?.data?.data?.description,
            cover_index: response?.data?.data?.cover_index,

            warranty: {
                "value": getDropdownValue(constant.PRODUCT_WARRANTY, response?.data?.data?.warranty)?.value,
                "label": getDropdownValue(constant.PRODUCT_WARRANTY, response?.data?.data?.warranty)?.label,
            },
            isPolarised: {
                "value": getDropdownValue(constant.POLARIZED, response?.data?.data?.isPolarised).value,
                "label": getDropdownValue(constant.POLARIZED, response?.data?.data?.isPolarised).label,
            },
            weight: {
                "value": getDropdownValue(constant.PRODUCT_WEIGHT, response?.data?.data?.weight).value,
                "label": getDropdownValue(constant.PRODUCT_WEIGHT, response?.data?.data?.weight).label,
            },
            rim_type: {
                "value": getDropdownValue(constant.PRODUCT_RIM_TYPE, response?.data?.data?.rim_type).value,
                "label": getDropdownValue(constant.PRODUCT_RIM_TYPE, response?.data?.data?.rim_type).label,
            },
            product_type_id: {
                "value": getDropdownValue(type, response?.data?.data?.product_type.id).value,
                "label": getDropdownValue(type, response?.data?.data?.product_type.id).label,
                "code": getDropdownValue(type, response?.data?.data?.product_type.id).code,
            },
            lens_type: {
                "value": getDropdownValue(constant.LENS_TYPE, response?.data?.data?.lens_type).value,
                "label": getDropdownValue(constant.LENS_TYPE, response?.data?.data?.lens_type).label,
            },
            period_use: {
                "value": getDropdownValue(constant.PERIOD_USE, response?.data?.data?.period_use).value,
                "label": getDropdownValue(constant.PERIOD_USE, response?.data?.data?.period_use).label,
            },
            color_id: {
                "value": getDropdownValue(colors, response?.data?.data?.color_id)?.value,
                "label": getDropdownValue(colors, response?.data?.data?.color_id)?.label,
            },
            // TODO: If This page cash remove the "code" from here as well as from utils function ;)
            brand_id: {
                "value": getDropdownValue(brandData, response?.data?.data?.brand?.id)?.value,
                "label": getDropdownValue(brandData, response?.data?.data?.brand?.id)?.label,
                "code": getDropdownValue(brandData, response?.data?.data?.brand?.id)?.code,
                "brand_category": getDropdownValue(brandData, response?.data?.data?.brand?.id)?.brand_category,
            },
            gender: getDropdownData(response?.data?.data?.gender, constant.GENDER),
            shapes: getDropdown(response?.data?.data?.shapes, "id", "name"),
            specx_colors: getDropdown(response?.data?.data?.specx_colors, "id", "name"),
            lens_colors: getDropdown(response?.data?.data?.lens_colors, "id", "name"),
            features: getDropdownData(response?.data?.data?.features, constant.PRODUCT_FEATURES),
            product_materials: getDropdown(response?.data?.data?.product_materials, "id", "name"),
            images: response?.data?.data?.images
        }
        if ( editProductData?.brand_id?.brand_category === constant.BRAND_CATEGORY[1].value) {
            setIsUnBranded(true)
        } else {
            setIsUnBranded(false)
        }
        setProductData(editProductData)
    }


    return (
        <Wrapper>
            <AddProduct
                productData={productData}
                setProductData={setProductData}
                productType={productType}
                brand={brand}
                productShape={productShape}
                productMaterial={productMaterial}
                productColor={productColor}
                glassSizes={glassSizes}
                power={power}
                templeSizes={templeSizes}
                bridgeSizes={bridgeSizes}
                imageUrl={previewImageUrl}
                brandResponse={brandResponse}
                setProductType={setProductType}
                getDropdown={getDropdown}
                addProducts={() => { addProducts() }}
                setIsUnBranded={setIsUnBranded}
                isUnBranded={isUnBranded}
                isDuplicateValue={isDuplicateValue}
                isContactLensProduct={isContactLensProduct}
                setIsContactLensProduct={setIsContactLensProduct}
                apiInprogress={apiInprogress}
            />
        </Wrapper>
    )
}
export default Index