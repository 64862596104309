import { useContext } from "react"
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import { Context } from "../Context"
import { PermissionData } from "../Context"
import * as XLSX from "xlsx"
import { BASE_URL } from "./defaultValues";
import constant from "./constant";
import { ModifiedDataForContactLensProps } from "./interface";

export const getDropdownValue = (constant: any, ind: any) => {
    let temp: any = {
        "value": 0,
        "label": '',
    }
    constant.map((item: any) => {
        if (item.value === ind) {
            temp = item 
            temp.value = item.value
            temp.label = item.label
        }
    })
    return temp
}
export const getUniqueObj = (data: any, id: string, compareId: string) => {
    let tempObj: any
    data && data.map((item: any) => {
        if (item[id] === compareId) {
            tempObj = item
        }
    })
    return tempObj
}
export const getDropdown = (response: [], name: string) => {
    let dropdown: any = []
    response && response.map((value: any) => {
        dropdown.push({
            "value": value.id,
            "label": value[name],
        }
        )
    })
    return dropdown
}

export const handleNumber = (e: any) => {
    const inputValue = e.target.value;
    const digitsOnly = inputValue.replace(/\D/g, '');
    if (digitsOnly.length <= 10) {
        return digitsOnly
    }
}

export const checkPermissionsInArray = (code: string) => {
    const store_admin_scope = localStorage.getItem("scope")
    const [userPermission, setUserPermission] = useContext<string[]>(PermissionData);
    if(store_admin_scope !== "3"){
        return userPermission?.includes(code)
    }else{
        return true
    }
};

export const findConstantLabel = (constant: { label: string, value: string | number }[], value: string | number) => {
    const warranty: any = constant.find((item: any) => item.value === value);
    return warranty ? warranty.label : 'Unknown';
}

export const numberWithCommas = (number: number) => {
    return number.toLocaleString('en-IN'); // Use 'en-IN' for the Indian numbering system
};

export const handleReportDownload = async (response: any, name: string, report_format: number, report_heading:string) => {
    let path = response
    let fullPath = `${BASE_URL}${path}`
    let cleanPAth = fullPath.replace(/api\/\.\//, '')
    let fileName = cleanPAth.substring(cleanPAth.lastIndexOf("/") + 1)
    // window.open(`${cleanPAth}`, '_blank')

    if (report_format === constant?.DailyReportFormat[0].value) {

        // convert the data to json
        const xlsResponse = await fetch(cleanPAth);
        const xlsData = await xlsResponse.arrayBuffer();

        // Convert XLS data to JSON
        const workbook = XLSX.read(new Uint8Array(xlsData), { type: 'array' });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(sheet);
        generatePdfHeader(jsonData, 4, name, report_heading)
    }

    if (report_format === constant?.DailyReportFormat[1].value) {

        setTimeout(() => {
            fetch(`${cleanPAth}`).then((response) => {
                response.blob().then((blob) => {

                    // Creating new object of PDF file
                    const fileURL = window.URL.createObjectURL(blob);

                    // Setting various property values
                    let alink = document.createElement("a");
                    alink.href = fileURL;
                    alink.download = `${fileName}`;
                    alink.click();
                });
            });
        }, 2000);
    }
}

export const convertJsonToExcel = (jsonData: any, filename: any) => {
    const ws = XLSX.utils.json_to_sheet(jsonData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet 1');
    XLSX.writeFile(wb, `${filename}.xlsx`);
};

export const convertArrayToKeyValue = (array: []) => {
    return array.map(size => ({
        label: size,
        value: size
    }));
}

// for generate pdf

const addTotalLine = (data: any, pdfName: string) => {
    if (pdfName === 'sales-return-report') {
        let totalGrossSales = data[data?.length - 1]["S.No."]
        let totalDiscount = data[data?.length - 1]["Credit Note No."]
        let totalNetSales = data[data?.length - 1]["Credit Note Date"]
        let totalSalesExcludingGST = data[data?.length - 1]["Credit Note Amount"]
        let totalGST = data[data?.length - 1]["Original Invoice No."]

        data.pop()
        const newObject = {
            "id": data[data?.length - 1],
            "S.No.": data[data?.length],
            "Credit Note No.": "",
            "Credit Note Date": "",
            "Credit Note Amount": "",
            "Original Invoice No.": "",
            "Original Invoice Date": "",
            "Customer Name": "",
            "Company Name": "",
            "GST No.": "",
            "Barcode": "",
            "Gross Sales": totalGrossSales,
            "Discount": totalDiscount,
            "Net Sales": totalNetSales,
            "Sales Excluding GST": totalSalesExcludingGST,
            "GST": totalGST,
            "Product Amount": "",
            "Additional Charge": "",
            "Loyalty Points Charges": "",
            "Cost of Good Sold (EX GST)": ""
        }

        // Add the new object to the end of the array
        data.push(newObject);

    }
    return data
}

export const generatePdfHeader = (data: any, countOfRowHaveData: number, pdfName: string, report_heading:string) => {
    if (data) {
        if (data && data.length >= countOfRowHaveData) {
            const headerRow = data[countOfRowHaveData - 1]; // Get the fifth-to-last row
            const headerValues = Object.values(headerRow);
            generatePdfData(data, headerValues, countOfRowHaveData, pdfName, report_heading)
        }
    }
}

const generatePdfData = (data: any, header: any, countOfRowHaveData: number, pdfName: string, report_heading:string) => {
    if (!data) return;

    const dataLength = data.length;

    if (dataLength === countOfRowHaveData) {
        return;
    }

    const pdfData = data.slice(countOfRowHaveData).map((row: any, index: number) => {
        const headerValues = Object.values(row);
        const obj: any = { id: index + 1 };
        header.forEach((key: any, j: number) => {
            obj[key] = headerValues[j];
        });
        return obj;
    });

    const getValueFromString = (valueArr: any, name: string) => {
        let lastIndex = valueArr.lastIndexOf([name]); // last occurrence of space
        let value = valueArr.substring(lastIndex + 1);
        return value
    }

    // (0,1,2) are the row in which storeName , date in the xlsx last 0 add to access the data form array
    // ":","n","m", is take to separate the string it take from xlsx 
    pdfData.reportHeading = report_heading ? report_heading : ''
    pdfData.storeName = getValueFromString(Object?.values(data[0])[0], ":")
    pdfData.reportGeneratedOn = ((getValueFromString(Object?.values(data[1])[0], "n"))?.split(" ")[1]);
    pdfData.reportIsFrom = getValueFromString(Object?.values(data[2])[0], "m")


    generatePDF(header, addTotalLine(pdfData, pdfName), pdfName);
};

const createHeaders = (keys: string[]) => {
    const result = keys.map(key => {
        return {
            id: key,
            name: key.charAt(0).toUpperCase() + key.slice(1).replace(/([A-Z])/g, ' $1').replace(/_/g, ' ').trim(),
            prompt: key.charAt(0).toUpperCase() + key.slice(1).replace(/_/g, ' '),
            width: 65,
            align: "center",
            padding: 0
        };
    });
    return result;
};

const generatePDF = (header: any[], pdfData: any, pdfName: string) => {
    const headers: any[] = createHeaders(header);

    const doc = new jsPDF({ putOnlyUsedFonts: true, orientation: "landscape", format: 'a4' });
    const data = pdfData?.map((entry: any) => ({
        ...entry,
        id: entry.id.toString() // Convert id to string explicitly
    }));

    // Add heading
    const heading = `${pdfData?.reportHeading}`
    doc.setFontSize(20);
    doc.text(heading, 100, 10);

    // Add Store Name
    const report_store_name = `${pdfData?.storeName}`;
    doc.setFontSize(10);
    doc.text(`Store Name`, 15, 15);
    doc.setTextColor(80, 90, 111);
    doc.text(`${report_store_name}`, 35, 15);


    // Add date
    const report_date_to = `${pdfData?.reportGeneratedOn}`;
    doc.setFontSize(10);
    doc.setTextColor(0, 0, 0);
    doc.text(`Report Generated on`, 15, 20);
    doc.setTextColor(80, 90, 111);
    doc.text(`${report_date_to}`, 50, 20);


    const report_date_from = ` 2024-02-21 to 2024-02-21`;
    doc.setFontSize(10);
    doc.setTextColor(0, 0, 0);
    doc.text(`Report is from`, 15, 25);
    doc.setTextColor(80, 90, 111);
    doc.text(`${report_date_from}`, 40, 25);



    (doc as any).autoTable({
        head: [headers.map(header => header.prompt)],
        body: data.map((row: any) => headers.map(header => row[header.id])),
        startY: 30,// Adjust starting Y position as needed,
    });
    doc.save(`${pdfName}.pdf`);
};

// generate pdf code end here
export const getParams = (path: string) => {
    let urlParams = new URLSearchParams(location.search);
    let typeValue:any = urlParams?.get(path)
    return typeValue
}

export const isActiveQuery = (queryKey: any, queryValue: any) => {
    const urlParams = new URLSearchParams(location.search);
    return urlParams?.get(queryKey) == queryValue;
};

export const modifiedDataForContactLens = ({ data, filterCondition }: ModifiedDataForContactLensProps) => {
    return  data?.filter(filterCondition) || []
}


export const getContactLensType = () => {
    const storedValue:any = localStorage.getItem('isContactLensProduct') === 'true'
    if (storedValue) {
        try {
            return JSON.parse(storedValue);
        } catch (error) {
            console.error('Error parsing localStorage value:', error);
            return false; 
        }
    }
    return false;
}
export  const renderOptions = (
    options: any[],
    selectedValue: any,
    property: string,
    handleClick: (productId: any) => void,
    isPeriod?: boolean
  ) => {
    return options?.map((option: any, index: number) => {
      const isSelected = selectedValue === option[property];
      return (
        <p
        className={`glass-div cursor-pointer fw-700 fs-md-18 fs-12 my-auto ${
            isSelected ? 'glass-div-selected' : ''
          }`}
          key={`glass${index}`}
          onClick={() => handleClick(option.product_id)}
        >
       {isPeriod ? getDropdownValue(constant.PERIOD_USE, option[property])?.label : option[property]}
        </p>
      );
    });
  };
  export const removeNewlines = (input: string): string => {
    return input?.replace(/\n/g, '');
  };


  export const handleInventoryDownload = async (response: any, name: string,) => {
    let path = response
    let fullPath = `${path}`
    let cleanPAth = fullPath.replace(/api\/\.\//, '')
    let fileName = name ? `${name}${cleanPAth.lastIndexOf("/") + 1}` : cleanPAth.substring(cleanPAth.lastIndexOf("/") + 1)

        setTimeout(() => {
            fetch(`${cleanPAth}`).then((response) => {
                response.blob().then((blob) => {

                    // Creating new object of PDF file
                    const fileURL = window.URL.createObjectURL(blob);

                    // Setting various property values
                    let alink = document.createElement("a");
                    alink.href = fileURL;
                    alink.download = `${fileName}`;
                    alink.click();
                });
            });
        }, 2000);
}