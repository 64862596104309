const Procurement = {
  viewOrderHistory: () =>'store/procurements',
  OrderReceivedCancelledList:()=>'store/procurements-items',
  orderHistoryItems:(id:string)=>`store/procurement/${id}`,
  
  cancelApi:(id:string)=>`store/procurement/${id}/cancel`,
  transferRequestCancelledMoveOutList:()=>'store/requested-procurements/history',
  transferRequestedList:()=>'store/requested-procurements',

  // internal product
  internalTransfer:()=>'store/internal-movement/products',
  }
  export default Procurement