import React, { useEffect, useRef, useState } from "react";
import atoms from "../../../atoms";
import molecules from "../../../molecules";
import CopyIcon from "../../../../assets/Images/copy.svg";
import { AddInvoice, ProductData, AddInvoiceData } from "./interface";
import { useParams } from "react-router-dom";
import constant from "../../../../constants/constant";
import { getUniqueObj, numberWithCommas } from "../../../../constants/utils";
import PlaceHolderImage from "../../../../assets/Images/placeholderimg.png";
import { validationRules } from "./Validation";
import { PiFileArrowDownLight } from 'react-icons/pi';
import { IoIosCloseCircleOutline } from "react-icons/io";
import CloseIcon from '../../../../assets/Images/close.svg'
import './style.scss'
const { Image, Select, Input, Button, Search, CustomTable, TextArea, FileInput }: any =
  atoms;
const { SearchTab, TopBar } = molecules;
const AddInventory: React.FC<AddInvoice> = ({
  getProductData,
  search,
  setSearch,
  productData,
  previewTab,
  setPreviewTab,
  setAllProductList,
  handleSearchSelect,
  allProductList,
  setProductData,
  totalProduct,
  setTotalProduct,
  invoiceData,
  setInvoiceData,
  setButtonClick,
  isNewInvoice, setIsNewInvoice,
  supplierData
}) => {
  let params = useParams();
  let invoiceEditID = params.id || "";
  const [errors, setErrors] = useState<any>({});
  const fileRef: any = useRef()
  const [productDataTable, setProductDataTable] = useState<any>();

  const searchTabContainer = useRef<HTMLDivElement>(null);
  const columns = [
    {
      name: "Product Type",
      selector: (row: ProductData) =>
        row.modal_number && row?.product_type?.name,
      sortable: true,
    },
    {
      name: "Cover Photo",
      selector: (row: ProductData) =>
        row.modal_number && (
          <img
            src={
              row?.images &&
              (row?.images?.length > 0 ? row?.images[0] : PlaceHolderImage)
            }
            className="w-100 rounded object-contain"
          />
        ),
    },
    {
      name: "Brand",
      selector: (row: ProductData) =>
        row.modal_number && row?.brand?.brand_name,
    },
    {
      name: "Model Number",
      selector: (row: ProductData) => row.modal_number && row.modal_number,
    },
    {
      name: "Brand Color",
      selector: (row: ProductData) => row.modal_number && row.brand_color_code,
    },
    {
      name: "Glass Size/Power",
      selector: (row: ProductData) => row.modal_number && row.glass_size,
    },
    {
      name: "SRP (₹)",
      selector: (row: ProductData) =>
        row.modal_number && numberWithCommas(row.product_srp),
    },
    {
      name: "With Case",
      selector: (row: ProductData) =>
        row.modal_number && (row?.product_with_case ? "Yes" : "No"),
    },
    {
      name: "SW Landing Cost(%)",
      selector: (row: ProductData) =>
        row.modal_number &&
        getUniqueObj(
          row?.brand?.product_types,
          "product_type_id",
          row?.product_type_id
        )?.specx_landing_cost,
    },
    {
      name: "Cost Amount (₹)",
      selector: (row: ProductData) => row.modal_number && row.cost_amount,
    },
    {
      name: "QTY",
      selector: (row: ProductData) => row.modal_number && row.qty,
    },
    {
      name: "Total Price(₹)",
      selector: (row: ProductData) =>
        row.modal_number &&
        numberWithCommas(parseFloat(row.total_price?.toFixed(2))),
    },
    {
      name: "Status",
      selector: (row: ProductData) => row.modal_number && row.isActive,
    },
    {
      name: "Verification Status",
      selector: (row: ProductData) =>
        row.modal_number && row.verification_status,
    },
    { name: "Action", selector: (row: any) => row.modal_number && row.action },
  ];
  const contactLensColumns = [
    { name: 'Product Type', selector: (row: ProductData, index: number) => row?.product_type?.name, sortable: true },
    { name: 'Brand', selector: (row: ProductData, index: number) => row?.brand?.brand_name },
    { name: 'Lens Color', selector: (row: ProductData | any, index: number) => row.color?.color_name },
    {
      name: "SRP (₹)",
      selector: (row: ProductData) => numberWithCommas(row.product_srp),
    },
    { name: 'Cost Amount (₹)', selector: (row: ProductData, index: number) => row?.cost_amount },
    { name: 'QTY', selector: (row: ProductData, index: number) => row?.qty },
    { name: 'Total Price(₹)', selector: (row: ProductData, index: number) => numberWithCommas(parseFloat(row.total_price?.toFixed(2))) },
    { name: 'Status', selector: (row: ProductData, index: number) => row.isActive },
    { name: 'Verification Status', selector: (row: ProductData, index: number) => row.verification_status },
    { name: 'Action', selector: (row: any, index: number) => row.action, },
  ]
  const handleCalculation = (type: any, index: number, value: string) => {
    let tempData = [...productData];
    tempData[index][type] = value;
    tempData[index].total_price =
      tempData[index].cost_amount * tempData[index].qty;
    tempData[index].gst_amount =
      (tempData[index].total_price *
        tempData[index].product_type.gst_percentage) /
      (100 + tempData[index].product_type.gst_percentage);
    tempData[index].srp_amount =
      tempData[index].product_srp * tempData[index].qty;
    setProductData(tempData);
    handleTotal(tempData);
  };
  const handleTotal = (data: any) => {
    if (data) {
      let tempData = [...data];
      let totalPrice = 0;
      let totalQty = 0;
      let totalProductCase = 0;
      let totalGstAmount = 0;
      let totalSrpAmount = 0;
      let tempProductDetail: any = [];
      tempData &&
        tempData.map((item: ProductData) => {
          tempProductDetail.push({
            product_id: item.id,
            cost_amount: item.cost_amount,
            quantity: item.qty,
            gst_amount: item.gst_amount.toFixed(2),
            srp_amount: item.srp_amount,
          });
          totalQty += Number(item.qty);
          totalPrice += Number(item.total_price);
          totalGstAmount += Number(item.gst_amount);
          if (item.product_with_case) {
            totalProductCase += Number(item.qty);
          }
          totalSrpAmount += Number(item.srp_amount);
        });
      setInvoiceData((prevState: AddInvoiceData) => ({
        ...prevState,
        product_details: tempProductDetail,
      }));
      let tempProductData = {
        sum_qty: totalQty,
        sum_total_price: totalPrice,
        total_product_case: totalProductCase,
        total_gst_amount: totalGstAmount,
        total_srp_amount: totalSrpAmount,
      };
      setInvoiceData((prevState: AddInvoiceData) => ({
        ...prevState,
        total_product_case: totalProductCase,
      }));
      setInvoiceData((prevState: AddInvoiceData) => ({
        ...prevState,
        total_amount: totalPrice,
      }));
      setInvoiceData((prevState: AddInvoiceData) => ({
        ...prevState,
        total_gst: totalGstAmount,
      }));
      setTotalProduct(tempProductData);
    }
  };

  const handleRemoveProduct = (index: number, data: any) => {
    let tempData = [...productData];
    tempData?.splice(index, 1);
    let tempProductData = {
      sum_qty: data?.qty
        ? totalProduct?.sum_qty - data?.qty
        : totalProduct?.sum_qty,
      sum_total_price: data?.total_price
        ? totalProduct?.sum_total_price - data?.total_price
        : totalProduct?.sum_total_price,
      total_product_case: data?.product_with_case
        ? totalProduct?.total_product_case - data?.qty
        : totalProduct?.total_product_case,
    };
    setTotalProduct(tempProductData);
    setProductData(tempData);
    handleTotal(tempData);
  };

  const handleChange = (name: string, value: any) => {
    setInvoiceData((prevState: any) => ({ ...prevState, [name]: value }));
    setErrors((prevErrors: any) => ({ ...prevErrors, [name]: "" }));
  };

  const handleSearch = (e: string) => {
    setSearch(e);
    if (e?.length < 1) {
      setAllProductList([]);
    }
    let regex = new RegExp("[ ]+", "g");
    let searchItem = e.replace(regex, "");
    if (e[e.length - 1] != " ") {
      if (searchItem?.length > 2) {
        setErrors((prevErrors: any) => ({ ...prevErrors, productDetails: "" }));
        if (e?.length > 0) {
          getProductData(e);
          setPreviewTab(true);
        }
      }
    }
  };

  const handleClick = () => {
    const isValid = validate();
    handleTotal(productData);
    if (isValid) {
      setButtonClick(true);
    }
  };

  const validate = () => {
    let newErrors: any = {};
    for (const field in validationRules) {
      if (validationRules.hasOwnProperty(field)) {
        if (validationRules[field]?.required && !invoiceData[field]) {
          newErrors[field] = "This field is required.";
        } else if (
          validationRules[field]?.label?.required &&
          !invoiceData[field]?.label
        ) {
          newErrors[field] = "Please Select field";
        }
        if (invoiceData?.product_details?.length === 0) {
          newErrors.productDetails = "Please Select Product";
        }
      }
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleInvoiceId = () => {
    const date = new Date();
    let financialYear;
    if (date.getMonth() >= 3) {
      financialYear = `${date.getFullYear().toString().slice(-2)}-${(
        date.getFullYear() + 1
      )
        .toString()
        .slice(-2)}`;
    } else {
      financialYear = `${(date.getFullYear() - 1).toString().slice(-2)}-${date
        .getFullYear()
        .toString()
        .slice(-2)}`;
    }
    let firstLetter = invoiceData?.category?.label?.charAt(0);

    let tempInvoiceId = [
      financialYear,
      firstLetter,
      invoiceData?.invoice_count,
    ].join("_");
    setInvoiceData((prevState: any) => ({
      ...prevState,
      invoice_id: tempInvoiceId,
    }));
  };

  useEffect(() => {
    handleInvoiceId();
  }, [invoiceData?.invoice_count, invoiceData?.category]);
  useEffect(() => {
    if (productData) {
      const tempProductData = [...productData];
      setProductDataTable(tempProductData);
    }
  }, [productData]);

  const handleClickOutside = (event: any) => {
    if (
      searchTabContainer.current &&
      !searchTabContainer.current.contains(event.target)
    ) {
      setPreviewTab(false);
    }
  };
  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
  const FilterProduct = (data: any, type: boolean) => {
    let product = data && data?.filter((item: any) => item?.is_contact_lens === type)
    return product
  }
  const fileBase64 = (img: any) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.onerror = reject;
      fileReader.onload = () => resolve(fileReader.result);
      fileReader.readAsDataURL(img);
    });
  };

  // const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   setIsNewInvoice(false);

  //   const files = e.target.files;
  //   if (!files) return; // Ensure files are present

  //   const updatedImageUrls = [...invoiceData.invoice_file]; // Clone current invoice_file array

  //   const filePromises = Array.from(files).map(file => {
  //     return fileBase64(file) // Assuming fileBase64 returns a Promise<string>
  //       .catch(error => {
  //         console.error(`Error converting file ${file.name} to Base64: `, error);
  //         return null; // Handle individual file conversion error
  //       });
  //   });

  //   Promise.all(filePromises)
  //     .then((urls) => {
  //       const validUrls = urls.filter(url => url !== null); // Exclude failed conversions
  //       setInvoiceData((prevState: any) => ({
  //         ...prevState,
  //         invoice_file: [...updatedImageUrls, ...validUrls],
  //       }));
  //     })
  //     .catch((error) => {
  //       console.error("Unexpected error processing files: ", error);
  //     });
  // };


  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsNewInvoice(false);

    const files = e.target.files;
    if (!files) return;

    const updatedImageUrls: string[] = [...invoiceData.invoice_file];

    const filePromises = Array.from(files).map((file) =>
      fileBase64(file)
        .then((base64String: any) => {
          // Remove the `data:[mime-type];base64,` prefix for any type
          return base64String.replace(/^data:.*;base64,/, "");
        })
        .catch((error) => {
          console.error(`Error converting file ${file.name} to Base64:`, error);
          return null;
        })
    );

    Promise.all(filePromises)
      .then((urls) => {
        const validUrls = urls.filter((url): url is string => url !== null);

        // Combine and remove duplicates
        const allUrls = [...updatedImageUrls, ...validUrls];
        const uniqueUrls = allUrls.filter((url, index) => allUrls.indexOf(url) === index);

        setInvoiceData((prevState: any) => ({
          ...prevState,
          invoice_file: uniqueUrls,
        }));
      })
      .catch((error) => {
        console.error("Unexpected error processing files:", error);
      });
  };


  const downloadFile = (base64String: string, filename: string) => {
    try {
      // Convert Base64 string to binary data
      const byteCharacters = atob(base64String);
      const byteNumbers = Array.from(byteCharacters).map((char) => char.charCodeAt(0));
      const byteArray = new Uint8Array(byteNumbers);

      // Guess the file type or use a generic binary type
      let fileType = "application/octet-stream"; // Fallback type for unknown file types
      if (filename.endsWith(".pdf")) {
        fileType = "application/pdf";
      } else if (filename.endsWith(".png")) {
        fileType = "image/png";
      } else if (filename.endsWith(".jpg") || filename.endsWith(".jpeg")) {
        fileType = "image/jpeg";
      } else if (filename.endsWith(".webp")) {
        fileType = "image/webp";
      }

      // Create a Blob from the binary data
      const blob = new Blob([byteArray], { type: fileType });

      // Create a temporary anchor tag for downloading
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = filename;

      // Trigger the download
      document.body.appendChild(link);
      link.click();

      // Clean up
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };
  const handleRemoveInvoices = (index: number) => {
    if (invoiceData?.invoice_file) {
      let tempData = [...invoiceData.invoice_file];
      tempData.splice(index, 1);
      setInvoiceData((prevState: any) => ({ ...prevState, invoice_file: tempData }));
    }
  };

  useEffect(() => {
    if (fileRef?.current) {
      fileRef.current.value = ''
    }
  }, [invoiceData?.invoice_file])
  const urlDownloadFile = (fileUrl: string, fileName: string) => {
    fetch(fileUrl)
      .then(response => response.blob())  // Fetch the file as a Blob
      .then(blob => {
        // Create a temporary URL for the Blob
        const link = document.createElement('a');
        const url = URL.createObjectURL(blob);
        link.href = url;
        link.download = fileName;  // Specify the file name
        link.click();

        // Clean up the URL object after the download starts
        URL.revokeObjectURL(url);
      })
      .catch(error => {
        console.error("Error downloading the file: ", error);
      });
  };
  return (
    <div>
      <div className="row align-items-center mb-4">
        <div className="col-md-6 col-12">
          <TopBar
            heading={!invoiceEditID ? "Add Inventory" : "Update Inventory"}
          />
        </div>

        <div className=" col-md-6 col-12 d-flex justify-content-end align-item-end gap-md-3 gap-1 align-items-center">
          <h2 className="mb-0 fs-18 fw-700 secondary-color ">
            Inv Addition ID:
          </h2>
          <div className="d-flex justify-content-between align-items-center px-2 py-2 add-inventory-product-custom-div cursor-pointer">
            <h2 className="mb-0 fs-16 fw-600 secondary-color">
              {invoiceData?.invoice_id}
            </h2>
            <div className="">
              <Image
                src={CopyIcon}
                onClick={() =>
                  navigator.clipboard.writeText(invoiceData?.invoice_id)
                }
              />
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className='col-md-3 col-12'>
          <Select label={"Category"}
            className={"w-100"} placeholder={"Select Category"}
            options={constant.STORE_INVOICE_CATEGORY}
            value={invoiceData?.category}
            errors={errors.category}
            // isOptionDisabled={(option: any) => 
            //   option.value !== constant.STORE_INVOICE_CATEGORY[0].value && 
            //   option.value !== constant.STORE_INVOICE_CATEGORY[1].value
            // }
            // isDisabled={invoiceEditID && !((invoiceData?.category.value === constant.STORE_INVOICE_CATEGORY[0].value) || (invoiceData?.category.value === constant.STORE_INVOICE_CATEGORY[1].value))}
            onChange={(e: any) => { handleChange("category", e) }}
          />
        </div>
        <div className="col-md-3 col-12">
          <Input
            label={"Supplier"}
            type="text"
            placeholder={"Select Supplier"}
            className={"w-100 zIndex-3"}
            value={invoiceData?.local_supplier_name}
            errors={errors.local_supplier_name}
            onChange={(e: any) => {
              handleChange("local_supplier_name", e.target.value);
            }}
          />
          {/* <Select
            label={"Supplier"}
            className={"w-100 zIndex-3"}
            placeholder={"Select Supplier"}
            options={supplierData && supplierData}
            value={invoiceData?.supplier_id}
            errors={errors.supplier_id}
            onChange={(e: any) => {
              handleChange("supplier_id", e);
            }}
          /> */}
        </div>
        <div className="col-md-3 col-12">
          <Input
            label={
              invoiceData?.category?.label === "SAMPLE"
                ? "Delivery Challan"
                : invoiceData?.category?.label === "WARRANTY"
                  ? "Claim ID"
                  : invoiceData?.category?.label === "FREE GOODS"
                    ? "Ref. Supplier Invoice Number"
                    : "Supplier Invoice Number"
            }
            type={"text"}
            placeholder={
              invoiceData?.category?.label === "SAMPLE"
                ? " Enter Delivery Challan"
                : invoiceData?.category?.label === "WARRANTY"
                  ? " Enter Claim ID"
                  : invoiceData?.category?.label === "FREE GOODS"
                    ? "Enter Supplier Invoice Number"
                    : "Enter Invoice Number"
            }
            value={invoiceData?.supplier_invoice_number}
            errors={errors.supplier_invoice_number}
            onChange={(e: any) => {
              handleChange("supplier_invoice_number", e.target.value);
            }}
          />
        </div>
        <div className="col-md-3 col-12">
          <Input
            label={"Invoice Date"}
            placeholder={"Enter Invoice Date"}
            type="date"
            value={invoiceData?.supplier_invoice_date}
            errors={errors.supplier_invoice_date}
            onChange={(e: any) => {
              handleChange("supplier_invoice_date", e.target.value);
            }}
          />
        </div>
      </div>
      <div className='mt-4'>
        <div className='d-flex'>
          <FileInput label={"Invoice File"} type="file"
            fileRef={fileRef}
            accept={"image/png, image/jpg, image/jpeg, image/webp, application/pdf"}
            // errors={errors.invoice_file}
            onChange={(e: any) => handleFileChange(e)}
            multiple={true}

          />

        </div>
      </div>
      <div className='mt-4 d-flex flex-wrap gap-4'>
        {invoiceData?.invoice_file &&
          invoiceData.invoice_file.map((item: string, index: number) => {
            const isUrl: any = (item: string) => /^https?:\/\/[^\s]+/.test(item);
            return (
              <div
                key={index} // Ensure a unique key for each item in the list
                className="invoice_file position-relative photo_upload"
              >
                {/* Download Button */}
                <div>
                  <Button
                    style={{ background: 'transparent', color: 'black' }}
                    onClick={() => {
                      if (isUrl(item)) {
                        urlDownloadFile(item, 'Invoice')
                      } else {
                        downloadFile(item, 'invoice');
                      }
                    }}
                  >
                    <PiFileArrowDownLight size={50} />
                  </Button>
                </div>

                {/* Delete Icon */}
                <div
                  style={{ top: '4px', right: '7px' }}
                  className="align-items-center d-flex delete-icon justify-content-center"
                  onClick={() => handleRemoveInvoices(index)}
                >
                  <Image src={CloseIcon} alt="Delete" />
                </div>
              </div>
            );
          })}
      </div>
      <div className="row justify-content-center mt-4" ref={searchTabContainer}>
        <div
          className="col-md-5 mx-auto position-relative p-0"
          onClick={() => setPreviewTab(!previewTab)}
        >
          <Search
            className="w-100"
            searchCallBack={(e: string) => {
              handleSearch(e);
            }}
            id="search"
            placeholder={"Search Product ID or Modal No."}
            type="search"
          />
          {search?.length > 0 && previewTab && (
            <SearchTab
              data={allProductList}
              searchCallBack={(e: ProductData) => handleSearchSelect(e)}
              productData={productDataTable}
            />
          )}
        </div>
      </div>

      <div>
        <>
          {productDataTable && productDataTable?.length > 0 ? (
            <CustomTable
              columns={columns}
              className="inventory-product-border"
              style={{ overFlow: "auto !import" }}
              data={FilterProduct(productDataTable, false)?.map(
                (item: ProductData, index: number) => {
                  return {
                    ...item,
                    cost_amount: (
                      <Input
                        value={item?.cost_amount}
                        className={"custom-input-height fs-12"}
                        type={"number"}
                        onChange={(e: any) =>
                          handleCalculation(
                            "cost_amount",
                            index,
                            e.target.value
                          )
                        }
                      />
                    ),
                    qty: (
                      <Input
                        value={item?.qty}
                        type={"number"}
                        className={"custom-input-height fs-12"}
                        onChange={(e: any) => {
                          handleCalculation("qty", index, e.target.value);
                        }}
                      />
                    ),
                    isActive: (
                      <div
                        className={`${item.isActive
                          ? "background-light-green"
                          : "background-light-red"
                          } border-product-radius`}
                      >
                        <div
                          className={`${item.isActive ? "color-green" : "color-red"
                            } fw-600  m-1 p-1`}
                        >
                          {item.isActive
                            ? constant.STATUS.ACTIVE
                            : constant.STATUS.DEACTIVE}
                        </div>
                      </div>
                    ),
                    verification_status:
                      item.verification_status ===
                        constant.VERIFICATION_STATUS.PENDING ? (
                        <div className="background-light-yellow border-product-radius">
                          <p className="color-yellow m-1 p-1">{"Pending"}</p>
                        </div>
                      ) : item.verification_status ===
                        constant.VERIFICATION_STATUS.VERIFIED ? (
                        <div className="background-light-green border-product-radius">
                          <p className="color-green m-1 p-1">{"Verified"}</p>
                        </div>
                      ) : item.verification_status ===
                        constant.VERIFICATION_STATUS.REWORK ? (
                        <div className="background-light-red border-product-radius">
                          <p className="color-red m-1 p-1">{"Rework"}</p>
                        </div>
                      ) : (
                        ""
                      ),
                    action: (
                      <div className="d-flex align-items-center">
                        <IoIosCloseCircleOutline
                          className="fs-24"
                          onClick={() => handleRemoveProduct(index, item)}
                        />
                      </div>
                    ),
                  };
                }
              )}
            />
          ) : (
            ""
          )}
          {productDataTable && FilterProduct(productDataTable, true)?.length > 0 && <div>
            <CustomTable columns={contactLensColumns}
              className="inventory-product-border"
              style={{ overFlow: 'auto !import' }}
              data={FilterProduct(productDataTable, true)?.map((item: ProductData, index: number) => {
                return ({
                  ...item,
                  cost_amount:
                    <Input
                      value={item?.cost_amount}
                      className={"custom-input-height fs-12"}
                      type={"number"}
                      onChange={(e: any) => handleCalculation("cost_amount", item.custom_index, e.target.value)}
                    />
                  ,
                  qty: <Input value={item?.qty} type={"number"} className={"custom-input-height fs-12"} onChange={(e: any) => { handleCalculation("qty", item.custom_index, e.target.value) }} />,
                  isActive:
                    <div className={`${item.isActive ? "background-light-green" : "background-light-red"} border-product-radius`}>
                      <div className={`${item.isActive ? "color-green" : "color-red"} fw-600  m-1 p-1`}>{item.isActive ? constant.STATUS.ACTIVE : constant.STATUS.DEACTIVE}</div></div>,
                  verification_status: item.verification_status === constant.VERIFICATION_STATUS.PENDING ? (
                    <div className="background-light-yellow border-product-radius"><p className="color-yellow m-1 p-1">{'Pending'}</p></div>) : item.verification_status === constant.VERIFICATION_STATUS.VERIFIED ?
                    (<div className="background-light-green border-product-radius"><p className="color-green m-1 p-1">{"Verified"}</p></div>) : item.verification_status === constant.VERIFICATION_STATUS.REWORK ? (<div className="background-light-red border-product-radius"><p className="color-red m-1 p-1">{"Rework"}</p></div>) : ''
                  ,
                  action:
                    <div className='d-flex align-items-center' >
                      <IoIosCloseCircleOutline className='fs-24' onClick={() => handleRemoveProduct(item.custom_index, item)} />
                    </div>
                })
              })}
            />
          </div>}
          {productData?.length > 0 && (
            <>
              <div className="d-flex row mt-4">
                <div className="col">
                  <h5 className="secondary-color fs-16 fw-600">
                    Total Product Case :{" "}
                    <span className="text-dark">
                      {totalProduct?.total_product_case?.toFixed(2)}
                    </span>
                  </h5>
                </div>
                <div className="col">
                  <h5 className="secondary-color fs-16 fw-600">
                    Total GST Amount :{" "}
                    <span className="text-dark">
                      {totalProduct?.total_gst_amount?.toFixed(2)}
                    </span>
                  </h5>
                </div>
                <div className="col">
                  <h5 className="secondary-color fs-16 fw-600">
                    Total SRP :{" "}
                    <span className="text-dark">
                      {totalProduct?.total_srp_amount?.toFixed(2)}
                    </span>
                  </h5>
                </div>
                <div className="col">
                  <h5 className="secondary-color fs-16 fw-600">
                    Total Price :{" "}
                    <span className="text-dark">
                      {numberWithCommas(
                        parseFloat(totalProduct?.sum_total_price?.toFixed(2))
                      )}
                    </span>
                  </h5>
                </div>
                <div className="col">
                  <h5 className="secondary-color fs-16 fw-600">
                    Total QTY:{" "}
                    <span className="text-dark">
                      {totalProduct?.sum_qty?.toFixed(2)}
                    </span>
                  </h5>
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-4">
                  <Input
                    label={"Total Actual Invoice (₹)"}
                    type={"number"}
                    placeholder={"Actual Invoice"}
                    value={invoiceData.actual_invoice}
                    errors={errors.actual_invoice}
                    onChange={(e: any) => {
                      handleChange("actual_invoice", e.target.value);
                    }}
                  />
                </div>
              </div>
            </>
          )}
        </>

      </div>
      <div className="mt-25">
        <TextArea
          label={"Remark"}
          placeholder={"Enter Your Remark Here"}
          value={invoiceData?.remark}
          errors={errors.remark}
          onChange={(e: any) => {
            handleChange("remark", e.target.value);
          }}
          rows={3}
        />
      </div>
      <div className="text-center mt-5 ">
        <Button className="w-25" onClick={() => handleClick()}>
          Continue
        </Button>
      </div>
    </div>
  );
};
export default AddInventory;
