import React, { useState } from 'react'
import atoms from '../../atoms'
import './style.scss'
import { FaPaperPlane } from 'react-icons/fa6'
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { removeNewlines } from '../../../constants/utils';
const { TextEditor, Button, CommonModal, TextArea } = atoms
const GreetingTab = ({ message, setMessage, shootMessage }: any) => {
  const [preview, setPreview] = useState(false)
  const UserName = 'Customer'
  const storeName = localStorage?.getItem('store_name')
  const storeContactNumber = localStorage?.getItem('store_contact_number')
  const dataObj = {
    name: 'Ram Sharma',
    address: 'Janki Nagar, Indore',
    message: 'I am hasgdfh hgasfb juahsd jashdf hjajsd fnmasjdf',
  };

  const handleSend = () => {
    setPreview(false)
    shootMessage()
    // setMessage('')

  }

  const toolbarOptions = [
    [{ 'header': '1' }, { 'header': '2' }],   // Header levels
    ['bold', 'italic', 'underline'],         // Text formatting
    [{ 'list': 'ordered' }, { 'list': 'bullet' }],  // List options
    ['link'],                               // Link option
    [{ 'align': [] }]                        // Text alignment
  ];



  return (
    <div className=''>
      <div className=''>
        <TextArea label={'Greeting Message'} placeholder='message' type="text" rows={3} value={message}
          onChange={(e: any) => setMessage(removeNewlines(e?.target?.value))}
        />
        {/* <TextEditor label={'Greeting Message'} value={message} onChange={setMessage} placeholder={'message ....'}  modules={{
            toolbar: false, 
          }}
          /> */}
      </div>
      <div className='d-flex justify-content-center mt-5'>
        <Button className="fs-16 fw-500 approve-btn w-25"
          isDisabled={!message}
          onClick={() => setPreview(true)}
        >Preview Message</Button>
      </div>
      <CommonModal isOpen={preview} toggle={() => setPreview(false)} title={'Message'}>
        <div className="whatsapp-frame">
          <div className="whatsapp-header">Specxweb</div>
          <div className="whatsapp-body">
            <div className="message-container">
              <p className="message-name">
                Hello <span className="message-highlight">{UserName} Ji</span>,
              </p>

              <p className="message-text">
                <i className='custom_line' dangerouslySetInnerHTML={{ __html: message }}   ></i>
              </p>
              <p className="message-additional-tag">at your service </p>
              <p className="message-address">{storeName}</p>
              <p className="message-footer">
                {storeContactNumber}
              </p>
            </div>
          </div>
          <div className="whatsapp-footer">
            <input
              type="text"
              placeholder="Type a message"
              className="message-input"
              value={message}
              // disabled
              onChange={(e: any) => setMessage(e.target.value)}
            />
            <button className="send-button" onClick={() => handleSend()}>
              <FaPaperPlane />
            </button>
          </div>
        </div>
      </CommonModal>
    </div>
  )
}

export default GreetingTab