import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

interface RichTextEditorProps {
  value: string;
  onChange: (value: string) => void;
  placeholder?: string;
  modules?:any;
  toolbar?: boolean; 
  label?:any; 
  htmlFor?:any
}

const RichTextEditor: React.FC<RichTextEditorProps> = ({
  value,
  onChange,
  placeholder = 'Type here...',
  toolbar = false,
  label,
  htmlFor
}) => {
  const customModule:any = {
    toolbar: toolbar ? true : false,
  };

  return (
    <div className="form-group">
    {label && <label className='inputs-label mb-2 fs-16 fw-600  secondary-color' htmlFor={htmlFor}>{label}</label>}
    <ReactQuill
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      modules={customModule}
    />
    </div>
  );
};

export default RichTextEditor;
