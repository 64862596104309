import { useContext, useState, useEffect } from "react";
import "./Sidebar.scss";
import Menu from "../../../assets/Images/menu.svg";
import Atoms from "../../atoms";
import { NavLink } from "react-router-dom";
import { BsArrowLeftRight, BsPerson } from "react-icons/bs";
import { BiCart } from "react-icons/bi";
import { NotificationCount } from "../../../Context";
import { AiOutlineBarChart } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import Images from "../../../assets/Images";
import constant from "../../../constants/constant";
import { RxDashboard } from "react-icons/rx";
import {
  checkPermissionsInArray,
  isActiveQuery,
} from "../../../constants/utils";
import { TbBrandBeats } from "react-icons/tb";
import { AllPermissions } from "../../../constants/permission";

const { Image, Badges } = Atoms;
const SideBar = () => {
  const navigate = useNavigate();
  const [notificationCount, setNotificationCount] =
    useContext<any>(NotificationCount);
  const [showAccounts, setShowAccounts] = useState<string>("");

  useEffect(() => {
    const urlSegments = window.location.href?.split("/").slice(0, 3);
    const derivedUrl =
      urlSegments?.length >= 3 ? urlSegments.join("/") : constant?.dev_URL;
    setShowAccounts(derivedUrl);
  }, []);

  return (
    <div>
      <div className="s-sidebar nav-scroll">
        <div className="s-layout__sidebar " style={{ position: "relative" }}>
          {/* <a className="s-sidebar__trigger" href="#0" > */}
          <button
            className="navbar-toggler shadow-none d-lg-none nav-toggle-icon-style"
            // style={{ height: "60px", position: "static", left: "1px", zIndex: 5 }}
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#s-sidebar__nav"
            aria-controls="s-sidebar__nav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <Image className="fs-16  border p-1 m-1" src={Menu} />
          </button>
          {/* </a> */}

          <nav
            className={`s-sidebar__nav ${window.innerWidth < 876 && "collapse"
              }`}
            id="s-sidebar__nav"
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              zIndex: 10005,
              width: "16em",
              height: "100%",
            }}
          >
            <ul style={{ marginBottom: "4rem" }}>
              <div
                className="w-100 text-center"
                data-bs-toggle={window.innerWidth < 770 ? "offcanvas" : ""}
                data-bs-target="#s-sidebar__nav"
                aria-controls="s-sidebar__nav"
                aria-expanded="false"
                aria-label="Toggle navigation"
                onClick={() => navigate("/dashboard")}
              >
                {/* <Image src={Logo} className='img-fluid logo' /> */}
                <Image
                  src={Images?.LoginImage}
                  className="img-fluid logo"
                  style={{
                    cursor: "pointer",
                    height: window?.innerWidth < 770 && "76px",
                  }}
                />
              </div>
              <div className="mt-25"></div>

              {checkPermissionsInArray(
                AllPermissions.store_Un_Recognize_Tab
              ) && (
                  <li>
                    <div className="accordion" id="unrecognizeAccordion">
                      <div className="accordion-item border-0">
                        <h2 className="accordion-header" id="unrecognizeHeading">
                          <button
                            className="collapsed accordion-button bg-body shadow-none s-sidebar__nav-link d-flex gap-3 align-items-center ps-4 text-decoration-none sidebar-menu-fs fs-14 black-color rounded-0"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#unrecognizeCollapse"
                            aria-expanded="false"
                            aria-controls="unrecognizeCollapse"
                          >
                            <AiOutlineBarChart className="fs-18" />
                            Unrecognize
                            <Badges
                              count={
                                notificationCount?.unrecognised_inventory_count +
                                notificationCount?.unrecognised_barcode_count +
                                notificationCount?.srp_revised_unrecognised_barcode_count
                              }
                              className="position-absolute top-2 right-16"
                            />
                          </button>
                        </h2>
                        <div
                          id="unrecognizeCollapse"
                          className="accordion-collapse collapse"
                          aria-labelledby="unrecognizeHeading"
                          data-bs-parent="#unrecognizeAccordion"
                        >
                          <div className="accordion-body p-0">
                            <ul className="list-unstyled">
                              {checkPermissionsInArray(
                                AllPermissions.store_Un_Brand
                              ) && (
                                  <NavLink
                                    className={`s-sidebar__nav-link d-flex gap-3 align-items-center ps-5 text-decoration-none sidebar-menu-fs fs-14 black-color
                            ${isActiveQuery(
                                      "brand-type",
                                      constant.BRAND_CATEGORY[1].value
                                    )
                                        ? "active"
                                        : "nav-deactive"
                                      }
                            `}
                                    to={`/brand?brand-type=${constant.BRAND_CATEGORY[1].value}`}
                                    data-bs-toggle={
                                      window.innerWidth < 770 ? "offcanvas" : ""
                                    }
                                    data-bs-target="#s-sidebar__nav"
                                    aria-controls="s-sidebar__nav"
                                    aria-expanded="false"
                                    aria-label="Toggle navigation"
                                    onClick={() =>
                                      navigate(
                                        `/brand?brand_type=${constant.BRAND_CATEGORY[1].value}`
                                      )
                                    }
                                  >
                                    <TbBrandBeats className="fs-20" />
                                    <li className="sidebar-list-styled">Brand</li>
                                  </NavLink>
                                )}

                              {checkPermissionsInArray(
                                AllPermissions.store_Un_Product
                              ) && (
                                  <li className="">
                                    <div
                                      className="accordion"
                                      id="accordionExample"
                                    >
                                      <div className="accordion-item border-0">
                                        <h2
                                          className="accordion-header "
                                          id="product_"
                                        >
                                          <button
                                            className="position-relative accordion-button bg-body shadow-none s-sidebar__nav-link d-flex gap-3 align-items-center ps-5 text-decoration-none sidebar-menu-fs fs-14 black-color rounded-0"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#_product"
                                            aria-expanded="false"
                                            aria-controls="_product"
                                          >
                                            <BiCart className="fs-18" />
                                            Product
                                          </button>
                                        </h2>
                                        <div
                                          id="_product"
                                          className="accordion-collapse collapse"
                                          aria-labelledby="product_"
                                          data-bs-parent="#accordionExample"
                                        >
                                          <div className="accordion-body p-0">
                                            <ul className="list-unstyled">
                                              {/* <li className="">
                                        <div className=''>
                                          <NavLink
                                            className={`s-sidebar__nav-link d-flex gap-3 align-items-center ps-6 text-decoration-none sidebar-menu-fs fs-14 black-color
                                            ${isActiveQuery('pending_product_type', constant.BRAND_CATEGORY[1].value) ? 'active' : 'nav-deactive'}
                                           `}
                                            to={`/pending-product?pending_product_type=${constant.BRAND_CATEGORY[1].value}`}
                                            data-bs-toggle={window.innerWidth < 770 ? "offcanvas" : ""}
                                            data-bs-target="#s-sidebar__nav"
                                            aria-controls="s-sidebar__nav"
                                            aria-expanded="false"
                                            aria-label="Toggle navigation"
                                            onClick={() => navigate(`/pending_product?pending_product_type=${constant.BRAND_CATEGORY[1].value}`)}>
                                            <AiOutlineBarChart className='fs-20' />
                                            <li className='sidebar-list-styled'>Pending</li>
                                          </NavLink>
                                        </div>
                                      </li> */}
                                              {checkPermissionsInArray(
                                                AllPermissions.store_Un_Sub_Product
                                              ) && (
                                                  <li className="">
                                                    <div className="">
                                                      <NavLink
                                                        className={`s-sidebar__nav-link d-flex gap-3 align-items-center ps-6 text-decoration-none sidebar-menu-fs fs-14 black-color
                                        ${isActiveQuery(
                                                          "product-type",
                                                          constant.BRAND_CATEGORY[1].value
                                                        )
                                                            ? "active"
                                                            : "nav-deactive"
                                                          }
                                        `}
                                                        to={`/product?product-type=${constant.BRAND_CATEGORY[1].value}`}
                                                        data-bs-toggle={
                                                          window.innerWidth < 770
                                                            ? "offcanvas"
                                                            : ""
                                                        }
                                                        data-bs-target="#s-sidebar__nav"
                                                        aria-controls="s-sidebar__nav"
                                                        aria-expanded="false"
                                                        aria-label="Toggle navigation"
                                                        onClick={() =>
                                                          navigate(
                                                            `/product?product-type${constant.BRAND_CATEGORY[1].value}`
                                                          )
                                                        }
                                                      >
                                                        <BsArrowLeftRight className="fs-20" />
                                                        <li className="sidebar-list-styled">
                                                          Product
                                                        </li>
                                                      </NavLink>
                                                    </div>
                                                  </li>
                                                )}
                                              {checkPermissionsInArray(
                                                AllPermissions.store_Un_Bulk_Product
                                              ) && (
                                                  <li className="">
                                                    <div className="">
                                                      <NavLink
                                                        className={`s-sidebar__nav-link d-flex gap-3 align-items-center ps-6 text-decoration-none sidebar-menu-fs fs-14 black-color
                                        ${isActiveQuery(
                                                          "bulk-product-type",
                                                          constant.BRAND_CATEGORY[1].value
                                                        )
                                                            ? "active"
                                                            : "nav-deactive"
                                                          }
                                        `}
                                                        to={`/bulk-upload-product?bulk-product-type=${constant.BRAND_CATEGORY[1].value}`}
                                                        data-bs-toggle={
                                                          window.innerWidth < 770
                                                            ? "offcanvas"
                                                            : ""
                                                        }
                                                        data-bs-target="#s-sidebar__nav"
                                                        aria-controls="s-sidebar__nav"
                                                        aria-expanded="false"
                                                        aria-label="Toggle navigation"
                                                        onClick={() =>
                                                          navigate(
                                                            `/bulk-upload-product?bulk-product-type=${constant.BRAND_CATEGORY[1].value}`
                                                          )
                                                        }
                                                      >
                                                        <BsArrowLeftRight className="fs-20" />
                                                        <li className="sidebar-list-styled">
                                                          Bulk Upload
                                                        </li>
                                                      </NavLink>
                                                    </div>
                                                  </li>
                                                )}
                                            </ul>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                )}

                              {checkPermissionsInArray(
                                AllPermissions.store_Un_Inventory
                              ) && (
                                  <li className="">
                                    <div
                                      className="accordion"
                                      id="accordionExample"
                                    >
                                      <div className="accordion-item border-0">
                                        <h2
                                          className="accordion-header "
                                          id="inventory_"
                                        >
                                          <button
                                            className="position-relative accordion-button bg-body shadow-none s-sidebar__nav-link d-flex gap-3 align-items-center ps-5 text-decoration-none sidebar-menu-fs fs-14 black-color rounded-0"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#inventory"
                                            aria-expanded="false"
                                            aria-controls="inventory"
                                          >
                                            <BiCart className="fs-18" />
                                            Inventory
                                            <Badges
                                              count={
                                                notificationCount?.unrecognised_inventory_count
                                              }
                                              className="position-absolute top-2 right-16"
                                            />
                                          </button>
                                        </h2>
                                        <div
                                          id="inventory"
                                          className="accordion-collapse collapse"
                                          aria-labelledby="inventory_"
                                          data-bs-parent="#accordionExample"
                                        >
                                          <div className="accordion-body p-0">
                                            <ul className="list-unstyled">
                                              {checkPermissionsInArray(
                                                AllPermissions.store_Un_Pending_Inventory
                                              ) && (
                                                  <NavLink
                                                    className={`s-sidebar__nav-link d-flex gap-3 align-items-center ps-6 text-decoration-none sidebar-menu-fs fs-14 black-color
                                        ${isActiveQuery(
                                                      "inventory-pending-type",
                                                      constant.BRAND_CATEGORY[1].value
                                                    )
                                                        ? "active"
                                                        : "nav-deactive"
                                                      }
                                        `}
                                                    to={`/inventory/pending?inventory-pending-type=${constant.BRAND_CATEGORY[1].value}`}
                                                    data-bs-toggle={
                                                      window.innerWidth < 770
                                                        ? "offcanvas"
                                                        : ""
                                                    }
                                                    data-bs-target="#s-sidebar__nav"
                                                    aria-controls="s-sidebar__nav"
                                                    aria-expanded="false"
                                                    aria-label="Toggle navigation"
                                                    onClick={() =>
                                                      navigate(
                                                        `/inventory/pending?inventory-pending-type=${constant.BRAND_CATEGORY[1].value}`
                                                      )
                                                    }
                                                  >
                                                    <BsArrowLeftRight className="fs-20" />
                                                    <li className="sidebar-list-styled">
                                                      Pending Inventory
                                                      <Badges
                                                        count={
                                                          notificationCount?.unrecognised_inventory_count
                                                        }
                                                        className=" right-16"
                                                      />
                                                    </li>
                                                  </NavLink>
                                                )}
                                              {checkPermissionsInArray(
                                                AllPermissions.store_Un_Approve_Inventory
                                              ) && (
                                                  <NavLink
                                                    className={`s-sidebar__nav-link d-flex gap-3 align-items-center ps-6 text-decoration-none sidebar-menu-fs fs-14 black-color
                                        ${isActiveQuery(
                                                      "inventory-approved-type",
                                                      constant.BRAND_CATEGORY[1].value
                                                    )
                                                        ? "active"
                                                        : "nav-deactive"
                                                      }
                                        `}
                                                    to={`/inventory/approved?inventory-approved-type=${constant.BRAND_CATEGORY[1].value}`}
                                                    data-bs-toggle={
                                                      window.innerWidth < 770
                                                        ? "offcanvas"
                                                        : ""
                                                    }
                                                    data-bs-target="#s-sidebar__nav"
                                                    aria-controls="s-sidebar__nav"
                                                    aria-expanded="false"
                                                    aria-label="Toggle navigation"
                                                    onClick={() =>
                                                      navigate(
                                                        `/inventory/approved?inventory-approved-type=${constant.BRAND_CATEGORY[1].value}`
                                                      )
                                                    }
                                                  >
                                                    <BsArrowLeftRight className="fs-20" />
                                                    <li className="sidebar-list-styled">
                                                      Approve Inventory
                                                    </li>
                                                  </NavLink>
                                                )}
                                              {checkPermissionsInArray(
                                                AllPermissions.store_Un_Search
                                              ) && (
                                                  <NavLink
                                                    className={`s-sidebar__nav-link d-flex gap-3 align-items-center ps-6 text-decoration-none sidebar-menu-fs fs-14 black-color
                                        ${isActiveQuery(
                                                      "inventory-search-type",
                                                      constant.BRAND_CATEGORY[1].value
                                                    )
                                                        ? "active"
                                                        : "nav-deactive"
                                                      }
                                        `}
                                                    to={`/inventory/search?inventory-search-type=${constant.BRAND_CATEGORY[1].value}`}
                                                    data-bs-toggle={
                                                      window.innerWidth < 770
                                                        ? "offcanvas"
                                                        : ""
                                                    }
                                                    data-bs-target="#s-sidebar__nav"
                                                    aria-controls="s-sidebar__nav"
                                                    aria-expanded="false"
                                                    aria-label="Toggle navigation"
                                                    onClick={() =>
                                                      navigate(
                                                        `/inventory/search?inventory-search-type=${constant.BRAND_CATEGORY[1].value}`
                                                      )
                                                    }
                                                  >
                                                    <BsArrowLeftRight className="fs-20" />
                                                    <li className="sidebar-list-styled">
                                                      Search
                                                    </li>
                                                  </NavLink>
                                                )}
                                            </ul>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                )}

                              {checkPermissionsInArray(
                                AllPermissions.store_Un_Barcode
                              ) && (
                                  <li className="">
                                    <div
                                      className="accordion"
                                      id="accordionExample"
                                    >
                                      <div className="accordion-item border-0">
                                        <h2
                                          className="accordion-header "
                                          id="barcode_"
                                        >
                                          <button
                                            className="position-relative accordion-button bg-body shadow-none s-sidebar__nav-link d-flex gap-3 align-items-center ps-5 text-decoration-none sidebar-menu-fs fs-14 black-color rounded-0"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#_barcode"
                                            aria-expanded="false"
                                            aria-controls="_barcode"
                                          >
                                            <BiCart className="fs-18" />
                                            Barcode
                                            <Badges
                                              count={
                                                notificationCount?.unrecognised_barcode_count +
                                                notificationCount?.srp_revised_unrecognised_barcode_count
                                              }
                                              className="position-absolute top-2 right-16"
                                            />
                                          </button>
                                        </h2>
                                        <div
                                          id="_barcode"
                                          className="accordion-collapse collapse"
                                          aria-labelledby="barcode_"
                                          data-bs-parent="#accordionExample"
                                        >
                                          <div className="accordion-body p-0">
                                            <ul className="list-unstyled">
                                              {checkPermissionsInArray(
                                                AllPermissions.store_Un_Pending_Barcode
                                              ) && (
                                                  <NavLink
                                                    className={`s-sidebar__nav-link d-flex gap-3 align-items-center ps-6 text-decoration-none sidebar-menu-fs fs-14 black-color
                                        ${isActiveQuery(
                                                      "pending-barcode-type",
                                                      constant.BRAND_CATEGORY[1].value
                                                    )
                                                        ? "active"
                                                        : "nav-deactive"
                                                      }
                                        `}
                                                    to={`/pending-barcode?pending-barcode-type=${constant.BRAND_CATEGORY[1].value}`}
                                                    data-bs-toggle={
                                                      window.innerWidth < 770
                                                        ? "offcanvas"
                                                        : ""
                                                    }
                                                    data-bs-target="#s-sidebar__nav"
                                                    aria-controls="s-sidebar__nav"
                                                    aria-expanded="false"
                                                    aria-label="Toggle navigation"
                                                    onClick={() =>
                                                      navigate(
                                                        `/pending-barcode?pending-barcode-type=${constant.BRAND_CATEGORY[1].value}`
                                                      )
                                                    }
                                                  >
                                                    <BsArrowLeftRight className="fs-20" />
                                                    <li className="sidebar-list-styled">
                                                      Pending-Barcode
                                                      <Badges
                                                        count={
                                                          notificationCount?.unrecognised_barcode_count
                                                        }
                                                      />
                                                    </li>
                                                  </NavLink>
                                                )}
                                              {checkPermissionsInArray(
                                                AllPermissions.store_Un_Approve_Barcode
                                              ) && (
                                                  <NavLink
                                                    className={`s-sidebar__nav-link d-flex gap-3 align-items-center ps-6 text-decoration-none sidebar-menu-fs fs-14 black-color
                                        ${isActiveQuery(
                                                      "approve-barcode-type",
                                                      constant.BRAND_CATEGORY[1].value
                                                    )
                                                        ? "active"
                                                        : "nav-deactive"
                                                      }`}
                                                    to={`/approve-barcode?approve-barcode-type=${constant.BRAND_CATEGORY[1].value}`}
                                                    data-bs-toggle={
                                                      window.innerWidth < 770
                                                        ? "offcanvas"
                                                        : ""
                                                    }
                                                    data-bs-target="#s-sidebar__nav"
                                                    aria-controls="s-sidebar__nav"
                                                    aria-expanded="false"
                                                    aria-label="Toggle navigation"
                                                    onClick={() =>
                                                      navigate(
                                                        `/approve-barcode?approve-barcode-type=${constant.BRAND_CATEGORY[1].value}`
                                                      )
                                                    }
                                                  >
                                                    <BsArrowLeftRight className="fs-20" />
                                                    <li className="sidebar-list-styled">
                                                      Approve-Barcode
                                                    </li>
                                                  </NavLink>
                                                )}
                                              {checkPermissionsInArray(
                                                AllPermissions.store_Un_Price_Updated_Barcode
                                              ) && (
                                                  <NavLink
                                                    className={`s-sidebar__nav-link d-flex gap-3 align-items-center ps-6 text-decoration-none sidebar-menu-fs fs-14 black-color
                                        ${isActiveQuery(
                                                      "status-price-updated-type",
                                                      constant.BRAND_CATEGORY[1].value
                                                    )
                                                        ? "active"
                                                        : "nav-deactive"
                                                      } `}
                                                    to={`/price-updated-barcode?status-price-updated-type=${constant.BRAND_CATEGORY[1].value}`}
                                                    data-bs-toggle={
                                                      window.innerWidth < 770
                                                        ? "offcanvas"
                                                        : ""
                                                    }
                                                    data-bs-target="#s-sidebar__nav"
                                                    aria-controls="s-sidebar__nav"
                                                    aria-expanded="false"
                                                    aria-label="Toggle navigation"
                                                    onClick={() =>
                                                      navigate(
                                                        `/price-updated-barcode?status-price-updated-type=${constant.BRAND_CATEGORY[1].value}`
                                                      )
                                                    }
                                                  >
                                                    <BsArrowLeftRight className="fs-20" />
                                                    <li className="sidebar-list-styled">
                                                      Price-Updated-Barcode
                                                      <Badges
                                                        count={
                                                          notificationCount?.srp_revised_unrecognised_barcode_count
                                                        }
                                                        className="mr-1"
                                                      />
                                                    </li>
                                                  </NavLink>
                                                )}

                                              {
                                                <NavLink
                                                  className={`s-sidebar__nav-link d-flex gap-3 align-items-center ps-6 text-decoration-none sidebar-menu-fs fs-14 black-color 
                                        ${isActiveQuery(
                                                    "lost-barcode-type",
                                                    constant.BRAND_CATEGORY[1].value
                                                  )
                                                      ? "active"
                                                      : "nav-deactive"
                                                    }`}
                                                  to={`/inventory/lost-barcode?lost-barcode-type=${constant.BRAND_CATEGORY[1].value}`}
                                                >
                                                  <BsArrowLeftRight className="fs-20" />
                                                  <li className="sidebar-list-styled">
                                                    Lost Barcode
                                                  </li>
                                                </NavLink>
                                              }
                                              {
                                                <NavLink
                                                  className={`s-sidebar__nav-link d-flex gap-3 align-items-center ps-6 text-decoration-none sidebar-menu-fs fs-14 black-color 
                                        ${isActiveQuery(
                                                    "history-type",
                                                    constant.BRAND_CATEGORY[1].value
                                                  )
                                                      ? "active"
                                                      : "nav-deactive"
                                                    }`}
                                                  to={`/inventory/lost-barcode-history?history-type=${constant.BRAND_CATEGORY[1].value}`}
                                                >
                                                  <BsArrowLeftRight className="fs-20" />
                                                  <li className="sidebar-list-styled">
                                                    Lost Barcode History
                                                  </li>
                                                </NavLink>
                                              }
                                            </ul>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                )}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                )}

              {checkPermissionsInArray(AllPermissions.store_Recognize_Tab) && (
                <li>
                  <div className="accordion" id="recognizeAccordion">
                    <div className="accordion-item border-0">
                      <h2 className="accordion-header" id="recognizeHeading">
                        <button
                          className="collapsed accordion-button bg-body shadow-none s-sidebar__nav-link d-flex gap-3 align-items-center ps-4 text-decoration-none sidebar-menu-fs fs-14 black-color rounded-0"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#recognizeCollapse"
                          aria-expanded="false"
                          aria-controls="recognizeCollapse"
                        >
                          <AiOutlineBarChart className="fs-18" />
                          Recognize
                          <Badges
                            count={
                              notificationCount?.recognised_inventory_count +
                              notificationCount?.recognised_barcode_count +
                              notificationCount?.srp_revised_recognised_barcode_count
                            }
                            className="position-absolute top-2 right-16"
                          />
                        </button>
                      </h2>
                      <div
                        id="recognizeCollapse"
                        className="accordion-collapse collapse"
                        aria-labelledby="recognizeHeading"
                        data-bs-parent="#recognizeAccordion"
                      >
                        <div className="accordion-body p-0">
                          <ul className="list-unstyled">
                            {checkPermissionsInArray(
                              AllPermissions.store_Brand
                            ) && (
                                <li className="">
                                  <div className="">
                                    <NavLink
                                      className={`s-sidebar__nav-link d-flex gap-3 ps-5 align-items-center text-decoration-none sidebar-menu-fs fs-14 black-color
                            ${isActiveQuery(
                                        "brand-type",
                                        constant.BRAND_CATEGORY[0].value
                                      )
                                          ? "active"
                                          : "nav-deactive"
                                        }
                            `}
                                      to={`/brand?brand-type=${constant.BRAND_CATEGORY[0].value}`}
                                      data-bs-toggle={
                                        window.innerWidth < 770 ? "offcanvas" : ""
                                      }
                                      data-bs-target="#s-sidebar__nav"
                                      aria-controls="s-sidebar__nav"
                                      aria-expanded="false"
                                      aria-label="Toggle navigation"
                                      onClick={() =>
                                        navigate(
                                          `/brand?brand_type=${constant.BRAND_CATEGORY[0].value}`
                                        )
                                      }
                                    >
                                      <TbBrandBeats className="fs-20" />
                                      <li className="sidebar-list-styled">
                                        Brand
                                      </li>
                                    </NavLink>
                                  </div>
                                </li>
                              )}
                            {/* 
                          {checkPermissionsInArray(AllPermissions.store_Product) && <li className="">
                            <div className="accordion" id="accordionProduct">
                              <div className="accordion-item border-0">
                                <h2 className="accordion-header " id="reco_product_">
                                  <button
                                    className="collapsed position-relative accordion-button bg-body shadow-none s-sidebar__nav-link d-flex gap-3 align-items-center ps-5 text-decoration-none sidebar-menu-fs fs-14 black-color rounded-0"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#_product"
                                    aria-expanded="false"
                                    aria-controls="_product"
                                  >
                                    <BiCart className="fs-18" />
                                    Product
                                  </button>
                                </h2>
                                <div
                                  id="_product"
                                  className="accordion-collapse collapse"
                                  aria-labelledby="reco_product_"
                                  data-bs-parent="#accordionProduct"
                                >
                                  <div className="accordion-body p-0">
                                    <ul className="list-unstyled">

                                      <li className="">
                                        <div className=''>
                                          <NavLink
                                            className={`s-sidebar__nav-link d-flex ps-5 gap-3 align-items-center text-decoration-none sidebar-menu-fs fs-14 black-color
                                            ${isActiveQuery('pending_product_type', constant.BRAND_CATEGORY[0].value) ? 'active' : 'nav-deactive'}
                                           `}
                                            to={`/pending-product?pending_product_type=${constant.BRAND_CATEGORY[0].value}`}
                                            data-bs-toggle={window.innerWidth < 770 ? "offcanvas" : ""}
                                            data-bs-target="#s-sidebar__nav"
                                            aria-controls="s-sidebar__nav"
                                            aria-expanded="false"
                                            aria-label="Toggle navigation"
                                            onClick={() => navigate(`/pending_product?pending_product_type=${constant.BRAND_CATEGORY[0].value}`)}>
                                            <AiOutlineBarChart className='fs-20' />
                                            <li className='sidebar-list-styled'>Pending</li>
                                          </NavLink>
                                        </div>
                                      </li>
                                      <li className="">
                                        <div className=''>
                                          <NavLink
                                            className={`s-sidebar__nav-link d-flex ps-5 gap-3 align-items-center text-decoration-none sidebar-menu-fs fs-14 black-color
                                            ${isActiveQuery('product-type', constant.BRAND_CATEGORY[0].value) ? 'active' : 'nav-deactive'}
                                            `}
                                            to={`/product?product-type=${constant.BRAND_CATEGORY[0].value}`}
                                            data-bs-toggle={window.innerWidth < 770 ? "offcanvas" : ""}
                                            data-bs-target="#s-sidebar__nav"
                                            aria-controls="s-sidebar__nav"
                                            aria-expanded="false"
                                            aria-label="Toggle navigation"
                                            onClick={() => navigate(`/product?product-type=${constant.BRAND_CATEGORY[0].value}`)}>
                                            <AiOutlineBarChart className='fs-20' />
                                            <li className='sidebar-list-styled'>Approved</li>
                                          </NavLink>
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>} */}

                            {checkPermissionsInArray(
                              AllPermissions.store_Product
                            ) && (
                                <li className="">
                                  <div
                                    className="accordion"
                                    id="accordionExample"
                                  >
                                    <div className="accordion-item border-0">
                                      <h2
                                        className="accordion-header "
                                        id="reco_product_"
                                      >
                                        <button
                                          className="collapsed position-relative accordion-button bg-body shadow-none s-sidebar__nav-link d-flex gap-3 align-items-center ps-5 text-decoration-none sidebar-menu-fs fs-14 black-color rounded-0"
                                          type="button"
                                          data-bs-toggle="collapse"
                                          data-bs-target="#_reco_product_"
                                          aria-expanded="false"
                                          aria-controls="_reco_product_"
                                        >
                                          <BiCart className="fs-18" />
                                          Product
                                        </button>
                                      </h2>
                                      <div
                                        id="_reco_product_"
                                        className="accordion-collapse collapse"
                                        aria-labelledby="reco_product_"
                                        data-bs-parent="#accordionExample"
                                      >
                                        <div className="accordion-body p-0">
                                          <ul className="list-unstyled">
                                            <li className="">
                                              <div className="">
                                                <NavLink
                                                  className={`s-sidebar__nav-link d-flex gap-3 align-items-center ps-6 text-decoration-none sidebar-menu-fs fs-14 black-color
                                            ${isActiveQuery(
                                                    "pending_product_type",
                                                    constant.BRAND_CATEGORY[0].value
                                                  )
                                                      ? "active"
                                                      : "nav-deactive"
                                                    }
                                           `}
                                                  to={`/pending-product?pending_product_type=${constant.BRAND_CATEGORY[0].value}`}
                                                  data-bs-toggle={
                                                    window.innerWidth < 770
                                                      ? "offcanvas"
                                                      : ""
                                                  }
                                                  data-bs-target="#s-sidebar__nav"
                                                  aria-controls="s-sidebar__nav"
                                                  aria-expanded="false"
                                                  aria-label="Toggle navigation"
                                                  onClick={() =>
                                                    navigate(
                                                      `/pending-product?pending_product_type=${constant.BRAND_CATEGORY[0].value}`
                                                    )
                                                  }
                                                >
                                                  <AiOutlineBarChart className="fs-20" />
                                                  <li className="sidebar-list-styled">
                                                    Pending
                                                  </li>
                                                </NavLink>
                                              </div>
                                            </li>
                                            {/* <li className="">
                                              <div className="">
                                                <NavLink
                                                  className={`s-sidebar__nav-link d-flex gap-3 align-items-center ps-6 text-decoration-none sidebar-menu-fs fs-14 black-color
                                        ${isActiveQuery(
                                                    "product-type",
                                                    constant.BRAND_CATEGORY[0].value
                                                  )
                                                      ? "active"
                                                      : "nav-deactive"
                                                    }
                                        `}
                                                  to={`/product?product-type=${constant.BRAND_CATEGORY[0].value}`}
                                                  data-bs-toggle={
                                                    window.innerWidth < 770
                                                      ? "offcanvas"
                                                      : ""
                                                  }
                                                  data-bs-target="#s-sidebar__nav"
                                                  aria-controls="s-sidebar__nav"
                                                  aria-expanded="false"
                                                  aria-label="Toggle navigation"
                                                  onClick={() =>
                                                    navigate(
                                                      `/product?product-type${constant.BRAND_CATEGORY[0].value}`
                                                    )
                                                  }
                                                >
                                                  <AiOutlineBarChart className="fs-20" />
                                                  <li className="sidebar-list-styled">
                                                    Approved
                                                  </li>
                                                </NavLink>
                                              </div>
                                            </li> */}
                                            {/* {
                                        <NavLink
                                          className={`s-sidebar__nav-link d-flex gap-3 align-items-center ps-6 text-decoration-none sidebar-menu-fs fs-14 black-color
                                        ${isActiveQuery('bulk-product-type', constant.BRAND_CATEGORY[0].value) ? 'active' : 'nav-deactive'}
                                        `}
                                          to={`/bulk-upload-product?bulk-product-type=${constant.BRAND_CATEGORY[0].value}`}
                                          data-bs-toggle={window.innerWidth < 770 ? "offcanvas" : ""}
                                          data-bs-target="#s-sidebar__nav"
                                          aria-controls="s-sidebar__nav"
                                          aria-expanded="false"
                                          aria-label="Toggle navigation"
                                          onClick={() => navigate(`/bulk-upload-product?bulk-product-type=${constant.BRAND_CATEGORY[0].value}`)}>
                                          <BsArrowLeftRight className='fs-20' />
                                          <li className='sidebar-list-styled'>Bulk Upload</li>
                                        </NavLink>} */}
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                              )}
                            {checkPermissionsInArray(
                              AllPermissions.store_Inventory
                            ) && (
                                <li className="">
                                  <div
                                    className="accordion"
                                    id="accordionExample"
                                  >
                                    <div className="accordion-item border-0">
                                      <h2
                                        className="accordion-header "
                                        id="reco_inventory_"
                                      >
                                        <button
                                          className="collapsed position-relative accordion-button bg-body shadow-none s-sidebar__nav-link d-flex gap-3 align-items-center ps-5 text-decoration-none sidebar-menu-fs fs-14 black-color rounded-0"
                                          type="button"
                                          data-bs-toggle="collapse"
                                          data-bs-target="#_inventory"
                                          aria-expanded="false"
                                          aria-controls="_inventory"
                                        >
                                          <BiCart className="fs-18" />
                                          Inventory
                                          <Badges
                                            count={
                                              notificationCount?.recognised_inventory_count
                                            }
                                            className="position-absolute top-2 right-16"
                                          />
                                        </button>
                                      </h2>
                                      <div
                                        id="_inventory"
                                        className="accordion-collapse collapse"
                                        aria-labelledby="reco_inventory_"
                                        data-bs-parent="#accordionExample"
                                      >
                                        <div className="accordion-body p-0">
                                          <ul className="list-unstyled">
                                            {checkPermissionsInArray(
                                              AllPermissions.store_Pending_Inventory
                                            ) && (
                                                <NavLink
                                                  className={`s-sidebar__nav-link d-flex gap-3 align-items-center ps-6 text-decoration-none sidebar-menu-fs fs-14 black-color
                                        ${isActiveQuery(
                                                    "inventory-pending-type",
                                                    constant.BRAND_CATEGORY[0].value
                                                  )
                                                      ? "active"
                                                      : "nav-deactive"
                                                    }
                                        `}
                                                  to={`/inventory/pending?inventory-pending-type=${constant.BRAND_CATEGORY[0].value}`}
                                                  data-bs-toggle={
                                                    window.innerWidth < 770
                                                      ? "offcanvas"
                                                      : ""
                                                  }
                                                  data-bs-target="#s-sidebar__nav"
                                                  aria-controls="s-sidebar__nav"
                                                  aria-expanded="false"
                                                  aria-label="Toggle navigation"
                                                  onClick={() =>
                                                    navigate(
                                                      `/inventory/pending?inventory-pending-type=${constant.BRAND_CATEGORY[0].value}`
                                                    )
                                                  }
                                                >
                                                  <BsArrowLeftRight className="fs-20" />
                                                  <li className="sidebar-list-styled">
                                                    Pending Inventory
                                                    <Badges
                                                      count={
                                                        notificationCount?.recognised_inventory_count
                                                      }
                                                      className="right-16"
                                                    />
                                                  </li>
                                                </NavLink>
                                              )}
                                            {checkPermissionsInArray(
                                              AllPermissions.store_Approve_Inventory
                                            ) && (
                                                <NavLink
                                                  className={`s-sidebar__nav-link d-flex gap-3 align-items-center ps-6 text-decoration-none sidebar-menu-fs fs-14 black-color
                                        ${isActiveQuery(
                                                    "inventory-approved-type",
                                                    constant.BRAND_CATEGORY[0].value
                                                  )
                                                      ? "active"
                                                      : "nav-deactive"
                                                    }
                                        `}
                                                  to={`/inventory/approved?inventory-approved-type=${constant.BRAND_CATEGORY[0].value}`}
                                                  data-bs-toggle={
                                                    window.innerWidth < 770
                                                      ? "offcanvas"
                                                      : ""
                                                  }
                                                  data-bs-target="#s-sidebar__nav"
                                                  aria-controls="s-sidebar__nav"
                                                  aria-expanded="false"
                                                  aria-label="Toggle navigation"
                                                  onClick={() =>
                                                    navigate(
                                                      `/inventory/approved?inventory-approved-type=${constant.BRAND_CATEGORY[0].value}`
                                                    )
                                                  }
                                                >
                                                  <BsArrowLeftRight className="fs-20" />
                                                  <li className="sidebar-list-styled">
                                                    Approve Inventory
                                                  </li>
                                                </NavLink>
                                              )}
                                            {checkPermissionsInArray(
                                              AllPermissions.store_Search
                                            ) && (
                                                <NavLink
                                                  className={`s-sidebar__nav-link d-flex gap-3 align-items-center ps-6 text-decoration-none sidebar-menu-fs fs-14 black-color
                                        ${isActiveQuery(
                                                    "inventory-search-type",
                                                    constant.BRAND_CATEGORY[0].value
                                                  )
                                                      ? "active"
                                                      : "nav-deactive"
                                                    }
                                        `}
                                                  to={`/inventory/search?inventory-search-type=${constant.BRAND_CATEGORY[0].value}`}
                                                  data-bs-toggle={
                                                    window.innerWidth < 770
                                                      ? "offcanvas"
                                                      : ""
                                                  }
                                                  data-bs-target="#s-sidebar__nav"
                                                  aria-controls="s-sidebar__nav"
                                                  aria-expanded="false"
                                                  aria-label="Toggle navigation"
                                                  onClick={() =>
                                                    navigate(
                                                      `/inventory/search?inventory-search-type=${constant.BRAND_CATEGORY[0].value}`
                                                    )
                                                  }
                                                >
                                                  <BsArrowLeftRight className="fs-20" />
                                                  <li className="sidebar-list-styled">
                                                    Search
                                                  </li>
                                                </NavLink>
                                              )}
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                              )}

                            {checkPermissionsInArray(
                              AllPermissions.store_Barcode
                            ) && (
                                <li className="">
                                  <div
                                    className="accordion"
                                    id="accordionExample"
                                  >
                                    <div className="accordion-item border-0">
                                      <h2
                                        className="accordion-header "
                                        id="reco_barcode_"
                                      >
                                        <button
                                          className="collapsed position-relative accordion-button bg-body shadow-none s-sidebar__nav-link d-flex gap-3 align-items-center ps-5 text-decoration-none sidebar-menu-fs fs-14 black-color rounded-0"
                                          type="button"
                                          data-bs-toggle="collapse"
                                          data-bs-target="#_barcode_"
                                          aria-expanded="false"
                                          aria-controls="_barcode_"
                                        >
                                          <BiCart className="fs-18" />
                                          Barcode
                                          <Badges
                                            count={
                                              notificationCount?.recognised_barcode_count + 
                                              notificationCount?.srp_revised_recognised_barcode_count
                                            }
                                            className="position-absolute top-2 right-16"
                                          />
                                        </button>
                                      </h2>
                                      <div
                                        id="_barcode_"
                                        className="accordion-collapse collapse"
                                        aria-labelledby="reco_barcode_"
                                        data-bs-parent="#accordionExample"
                                      >
                                        <div className="accordion-body p-0">
                                          <ul className="list-unstyled">
                                            {checkPermissionsInArray(
                                              AllPermissions.store_Pending_Barcode
                                            ) && (
                                                <NavLink
                                                  className={`s-sidebar__nav-link d-flex gap-3 align-items-center ps-6 text-decoration-none sidebar-menu-fs fs-14 black-color
                                        ${isActiveQuery(
                                                    "pending-barcode-type",
                                                    constant.BRAND_CATEGORY[0].value
                                                  )
                                                      ? "active"
                                                      : "nav-deactive"
                                                    }
                                        `}
                                                  to={`/pending-barcode?pending-barcode-type=${constant.BRAND_CATEGORY[0].value}`}
                                                  data-bs-toggle={
                                                    window.innerWidth < 770
                                                      ? "offcanvas"
                                                      : ""
                                                  }
                                                  data-bs-target="#s-sidebar__nav"
                                                  aria-controls="s-sidebar__nav"
                                                  aria-expanded="false"
                                                  aria-label="Toggle navigation"
                                                  onClick={() =>
                                                    navigate(
                                                      `/pending-barcode?pending-barcode-type=${constant.BRAND_CATEGORY[0].value}`
                                                    )
                                                  }
                                                >
                                                  <BsArrowLeftRight className="fs-20" />
                                                  <li className="sidebar-list-styled">
                                                    Pending Barcode
                                                    <Badges
                                                      count={
                                                        notificationCount?.recognised_barcode_count
                                                      }
                                                      className="right-16"
                                                    />
                                                  </li>
                                                </NavLink>
                                              )}
                                            {checkPermissionsInArray(
                                              AllPermissions.store_Approve_Barcode
                                            ) && (
                                                <NavLink
                                                  className={`s-sidebar__nav-link d-flex gap-3 align-items-center ps-6 text-decoration-none sidebar-menu-fs fs-14 black-color
                                        ${isActiveQuery(
                                                    "approve-barcode-type",
                                                    constant.BRAND_CATEGORY[0].value
                                                  )
                                                      ? "active"
                                                      : "nav-deactive"
                                                    }
                                        `}
                                                  to={`/approve-barcode?approve-barcode-type=${constant.BRAND_CATEGORY[0].value}`}
                                                  data-bs-toggle={
                                                    window.innerWidth < 770
                                                      ? "offcanvas"
                                                      : ""
                                                  }
                                                  data-bs-target="#s-sidebar__nav"
                                                  aria-controls="s-sidebar__nav"
                                                  aria-expanded="false"
                                                  aria-label="Toggle navigation"
                                                  onClick={() =>
                                                    navigate(
                                                      `/approve-barcode?approve-barcode-type=${constant.BRAND_CATEGORY[0].value}`
                                                    )
                                                  }
                                                >
                                                  <BsArrowLeftRight className="fs-20" />
                                                  <li className="sidebar-list-styled">
                                                    Approve Barcode
                                                  </li>
                                                </NavLink>
                                              )}
                                            {checkPermissionsInArray(
                                              AllPermissions.store_Price_Updated_Barcode
                                            ) && (
                                                <NavLink
                                                  className={`s-sidebar__nav-link d-flex gap-3 align-items-center ps-6 text-decoration-none sidebar-menu-fs fs-14 black-color
                                        ${isActiveQuery(
                                                    "status-price-updated-type",
                                                    constant.BRAND_CATEGORY[0].value
                                                  )
                                                      ? "active"
                                                      : "nav-deactive"
                                                    }
                                        `}
                                                  to={`/price-updated-barcode?status-price-updated-type=${constant.BRAND_CATEGORY[0].value}`}
                                                  data-bs-toggle={
                                                    window.innerWidth < 770
                                                      ? "offcanvas"
                                                      : ""
                                                  }
                                                  data-bs-target="#s-sidebar__nav"
                                                  aria-controls="s-sidebar__nav"
                                                  aria-expanded="false"
                                                  aria-label="Toggle navigation"
                                                  onClick={() =>
                                                    navigate(
                                                      `/price-updated-barcode?status-price-updated-type=${constant.BRAND_CATEGORY[0].value}`
                                                    )
                                                  }
                                                >
                                                  <BsArrowLeftRight className="fs-20" />
                                                  <li className="sidebar-list-styled">
                                                    Price Updated Barcode
                                                    <Badges
                                                        count={
                                                          notificationCount?.srp_revised_recognised_barcode_count
                                                        }
                                                        className="mr-1"
                                                      />
                                                  </li>
                                                </NavLink>
                                              )}
                                            {
                                              <NavLink
                                                className={`s-sidebar__nav-link d-flex gap-3 align-items-center ps-6 text-decoration-none sidebar-menu-fs fs-14 black-color 
                                        ${isActiveQuery(
                                                  "lost-barcode-type",
                                                  constant.BRAND_CATEGORY[0].value
                                                )
                                                    ? "active"
                                                    : "nav-deactive"
                                                  }`}
                                                to={`/inventory/lost-barcode?lost-barcode-type=${constant.BRAND_CATEGORY[0].value}`}
                                              >
                                                <BsArrowLeftRight className="fs-20" />
                                                <li className="sidebar-list-styled">
                                                  Lost Barcode
                                                </li>
                                              </NavLink>
                                            }
                                            {
                                              <NavLink
                                                className={`s-sidebar__nav-link d-flex gap-3 align-items-center ps-6 text-decoration-none sidebar-menu-fs fs-14 black-color 
                                        ${isActiveQuery(
                                                  "history-type",
                                                  constant.BRAND_CATEGORY[0].value
                                                )
                                                    ? "active"
                                                    : "nav-deactive"
                                                  }`}
                                                to={`/inventory/lost-barcode-history?history-type=${constant.BRAND_CATEGORY[0].value}`}
                                              >
                                                <BsArrowLeftRight className="fs-20" />
                                                <li className="sidebar-list-styled">
                                                  Lost Barcode History
                                                </li>
                                              </NavLink>
                                            }
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                              )}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              )}

              {/* for Procurement */}
              {checkPermissionsInArray(
                AllPermissions.store_Procurement_Tab
              ) && (
                  <li className="">
                    <div className="accordion" id="accordionExample">
                      <div className="accordion-item border-0">
                        <h2 className="accordion-header " id="headingTwo">
                          <button
                            className="collapsed accordion-button bg-body shadow-none s-sidebar__nav-link d-flex gap-3 align-items-center ps-4 text-decoration-none sidebar-menu-fs fs-14 black-color rounded-0"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#procurement"
                            aria-expanded="false"
                            aria-controls="procurement"
                          >
                            <BiCart className="fs-18" />
                            Procurement
                            <Badges
                              count={notificationCount?.purchase_cart_count}
                              className="position-absolute top-2 right-16"
                            />
                          </button>
                        </h2>
                        <div
                          id="procurement"
                          className="accordion-collapse collapse"
                          aria-labelledby="headingTwo"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body p-0">
                            <ul className="list-unstyled">
                              {checkPermissionsInArray(
                                AllPermissions.store_Product_List
                              ) && (
                                  <NavLink
                                    className="s-sidebar__nav-link d-flex gap-3 align-items-center ps-5 text-decoration-none sidebar-menu-fs fs-14 black-color"
                                    to="/product/list"
                                    data-bs-toggle={
                                      window.innerWidth < 770 ? "offcanvas" : ""
                                    }
                                    data-bs-target="#s-sidebar__nav"
                                    aria-controls="s-sidebar__nav"
                                    aria-expanded="false"
                                    aria-label="Toggle navigation"
                                    onClick={() => navigate("/product/list")}
                                  >
                                    <BsArrowLeftRight className="fs-20" />
                                    <li className="sidebar-list-styled">
                                      Products
                                    </li>
                                  </NavLink>
                                )}

                              {checkPermissionsInArray(
                                AllPermissions.store_Order_Cart
                              ) && (
                                  <NavLink
                                    className="s-sidebar__nav-link d-flex gap-3 align-items-center ps-5 text-decoration-none sidebar-menu-fs fs-14 black-color"
                                    to="/order-cart"
                                    data-bs-toggle={
                                      window.innerWidth < 770 ? "offcanvas" : ""
                                    }
                                    data-bs-target="#s-sidebar__nav"
                                    aria-controls="s-sidebar__nav"
                                    aria-expanded="false"
                                    aria-label="Toggle navigation"
                                    onClick={() => navigate("/order-cart")}
                                  >
                                    <BsArrowLeftRight className="fs-20" />
                                    <li className="sidebar-list-styled">
                                      Order Cart{" "}
                                      <Badges
                                        count={
                                          notificationCount?.purchase_cart_count
                                        }
                                      />
                                    </li>
                                  </NavLink>
                                )}
                              {checkPermissionsInArray(
                                AllPermissions.store_Order_History
                              ) && (
                                  <NavLink
                                    className="s-sidebar__nav-link d-flex gap-3 align-items-center ps-5 text-decoration-none sidebar-menu-fs fs-14 black-color"
                                    to="/procurement/order/history"
                                    data-bs-toggle={
                                      window.innerWidth < 770 ? "offcanvas" : ""
                                    }
                                    data-bs-target="#s-sidebar__nav"
                                    aria-controls="s-sidebar__nav"
                                    aria-expanded="false"
                                    aria-label="Toggle navigation"
                                    onClick={() =>
                                      navigate("/procurement/order/history")
                                    }
                                  >
                                    <BsArrowLeftRight className="fs-20" />
                                    <li className="sidebar-list-styled">
                                      Order History
                                    </li>
                                  </NavLink>
                                )}
                              {checkPermissionsInArray(
                                AllPermissions.store_Order_Received
                              ) && (
                                  <NavLink
                                    className="s-sidebar__nav-link d-flex gap-3 align-items-center ps-5 text-decoration-none sidebar-menu-fs fs-14 black-color"
                                    to="/procurement/order/received"
                                    data-bs-toggle={
                                      window.innerWidth < 770 ? "offcanvas" : ""
                                    }
                                    data-bs-target="#s-sidebar__nav"
                                    aria-controls="s-sidebar__nav"
                                    aria-expanded="false"
                                    aria-label="Toggle navigation"
                                    onClick={() =>
                                      navigate("/procurement/order/received")
                                    }
                                  >
                                    <BsArrowLeftRight className="fs-20" />
                                    <li className="sidebar-list-styled">
                                      Order Received
                                    </li>
                                  </NavLink>
                                )}
                              {checkPermissionsInArray(
                                AllPermissions.store_Order_Cancelled
                              ) && (
                                  <NavLink
                                    className="s-sidebar__nav-link d-flex gap-3 align-items-center ps-5 text-decoration-none sidebar-menu-fs fs-14 black-color"
                                    to="/procurement/cancelled"
                                    data-bs-toggle={
                                      window.innerWidth < 770 ? "offcanvas" : ""
                                    }
                                    data-bs-target="#s-sidebar__nav"
                                    aria-controls="s-sidebar__nav"
                                    aria-expanded="false"
                                    aria-label="Toggle navigation"
                                    onClick={() =>
                                      navigate("/procurement/cancelled")
                                    }
                                  >
                                    <BsArrowLeftRight className="fs-20" />
                                    <li className="sidebar-list-styled">
                                      Order Cancelled
                                    </li>
                                  </NavLink>
                                )}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                )}

              {/* Transfer Requests */}
              {checkPermissionsInArray(
                AllPermissions.store_Transfer_Requests_Tab
              ) && (
                  <li className="">
                    <div className="accordion" id="accordionExample">
                      <div className="accordion-item border-0">
                        <h2 className="accordion-header " id="accounts_">
                          <button
                            className="collapsed position-relative accordion-button bg-body shadow-none s-sidebar__nav-link d-flex gap-3 align-items-center ps-4 text-decoration-none sidebar-menu-fs fs-14 black-color rounded-0"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#requestedByOther"
                            aria-expanded="false"
                            aria-controls="requestedByOther"
                          >
                            <BiCart className="fs-18" />
                            Transfer Requests
                            {
                              <Badges
                                count={notificationCount?.other_req_count}
                                className="position-absolute top-2 right-16"
                              />
                            }
                          </button>
                        </h2>
                        <div
                          id="requestedByOther"
                          className="accordion-collapse collapse"
                          aria-labelledby="accounts_"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body p-0">
                            <ul className="list-unstyled">
                              {checkPermissionsInArray(
                                AllPermissions.store_Requested
                              ) && (
                                  <NavLink
                                    className="s-sidebar__nav-link d-flex gap-3 align-items-center ps-5 text-decoration-none sidebar-menu-fs fs-14 black-color"
                                    to="/transfer-request/requested"
                                    data-bs-toggle={
                                      window.innerWidth < 770 ? "offcanvas" : ""
                                    }
                                    data-bs-target="#s-sidebar__nav"
                                    aria-controls="s-sidebar__nav"
                                    aria-expanded="false"
                                    aria-label="Toggle navigation"
                                    onClick={() =>
                                      navigate("/transfer-request/requested")
                                    }
                                  >
                                    <BsArrowLeftRight className="fs-20" />
                                    <li className="sidebar-list-styled">
                                      Requested{" "}
                                      <Badges
                                        count={notificationCount?.other_req_count}
                                      />
                                    </li>
                                  </NavLink>
                                )}
                              {checkPermissionsInArray(
                                AllPermissions.store_Move_Out
                              ) && (
                                  <NavLink
                                    className="s-sidebar__nav-link d-flex gap-3 align-items-center ps-5 text-decoration-none sidebar-menu-fs fs-14 black-color"
                                    to="/transfer-request/moved-out"
                                    data-bs-toggle={
                                      window.innerWidth < 770 ? "offcanvas" : ""
                                    }
                                    data-bs-target="#s-sidebar__nav"
                                    aria-controls="s-sidebar__nav"
                                    aria-expanded="false"
                                    aria-label="Toggle navigation"
                                    onClick={() =>
                                      navigate("/transfer-request/moved-out")
                                    }
                                  >
                                    <BsArrowLeftRight className="fs-20" />
                                    <li className="sidebar-list-styled">
                                      Moved Out
                                    </li>
                                  </NavLink>
                                )}
                              {checkPermissionsInArray(
                                AllPermissions.store_Cancelled
                              ) && (
                                  <NavLink
                                    className="s-sidebar__nav-link d-flex gap-3 align-items-center ps-5 text-decoration-none sidebar-menu-fs fs-14 black-color"
                                    to="/transfer-request/cancelled"
                                    data-bs-toggle={
                                      window.innerWidth < 770 ? "offcanvas" : ""
                                    }
                                    data-bs-target="#s-sidebar__nav"
                                    aria-controls="s-sidebar__nav"
                                    aria-expanded="false"
                                    aria-label="Toggle navigation"
                                    onClick={() =>
                                      navigate("/transfer-request/cancelled")
                                    }
                                  >
                                    <BsArrowLeftRight className="fs-20" />
                                    <li className="sidebar-list-styled">
                                      Cancelled
                                    </li>
                                  </NavLink>
                                )}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                )}

               {/* Transfer Requests */}
               {checkPermissionsInArray(
                AllPermissions.store_Internal_Transfer_Tab
              ) && (
                  <li className="">
                    <div className="accordion" id="accordionExampleInternal">
                      <div className="accordion-item border-0">
                        <h2 className="accordion-header " id="accounts_Internal">
                          <button
                            className="collapsed position-relative accordion-button bg-body shadow-none s-sidebar__nav-link d-flex gap-3 align-items-center ps-4 text-decoration-none sidebar-menu-fs fs-14 black-color rounded-0"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#requestedByInternal"
                            aria-expanded="false"
                            aria-controls="requestedByInternal"
                          >
                            <BiCart className="fs-18" />
                            Internal Transfer Requests
                            {/* {
                              <Badges
                                count={notificationCount?.other_req_count}
                                className="position-absolute top-2 right-16"
                              />
                            } */}
                          </button>
                        </h2>
                        <div
                          id="requestedByInternal"
                          className="accordion-collapse collapse"
                          aria-labelledby="accounts_Internal"
                          data-bs-parent="#accordionExampleInternal"
                        >
                          <div className="accordion-body p-0">
                            <ul className="list-unstyled">
                              {checkPermissionsInArray(
                                AllPermissions.store_Internal_Moved_In_Tab
                              ) && (
                                  <NavLink
                                    className="s-sidebar__nav-link d-flex gap-3 align-items-center ps-5 text-decoration-none sidebar-menu-fs fs-14 black-color"
                                    to="/internal-request/request"
                                    data-bs-toggle={
                                      window.innerWidth < 770 ? "offcanvas" : ""
                                    }
                                    data-bs-target="#s-sidebar__nav"
                                    aria-controls="s-sidebar__nav"
                                    aria-expanded="false"
                                    aria-label="Toggle navigation"
                                    onClick={() =>
                                      navigate("/internal-request/request")
                                    }
                                  >
                                    <BsArrowLeftRight className="fs-20" />
                                    <li className="sidebar-list-styled">
                                      Move In{" "}
                                      {/* <Badges
                                        count={notificationCount?.other_req_count}
                                      /> */}
                                    </li>
                                  </NavLink>
                                )}
                              {checkPermissionsInArray(
                                AllPermissions.store_Internal_Moved_Out_Tab
                              ) && (
                                  <NavLink
                                    className="s-sidebar__nav-link d-flex gap-3 align-items-center ps-5 text-decoration-none sidebar-menu-fs fs-14 black-color"
                                    to="/internal-request/moved-out"
                                    data-bs-toggle={
                                      window.innerWidth < 770 ? "offcanvas" : ""
                                    }
                                    data-bs-target="#s-sidebar__nav"
                                    aria-controls="s-sidebar__nav"
                                    aria-expanded="false"
                                    aria-label="Toggle navigation"
                                    onClick={() =>
                                      navigate("/internal-request/moved-out")
                                    }
                                  >
                                    <BsArrowLeftRight className="fs-20" />
                                    <li className="sidebar-list-styled">
                                      Moved Out
                                    </li>
                                  </NavLink>
                                )}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                )}

              {checkPermissionsInArray(AllPermissions.store_Staff_Tab) && (
                <li className="">
                  <NavLink
                    className="s-sidebar__nav-link d-flex gap-3 align-items-center ps-4 text-decoration-none sidebar-menu-fs fs-14 black-color"
                    to="/staff"
                    data-bs-toggle={window.innerWidth < 770 ? "offcanvas" : ""}
                    data-bs-target="#s-sidebar__nav"
                    aria-controls="s-sidebar__nav"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                    onClick={() => navigate("/staff")}
                  >
                    <span>
                      <BsPerson className="fs-18" />
                    </span>
                    Staff List
                  </NavLink>
                </li>
              )}

              {checkPermissionsInArray(AllPermissions.store_Sub_Admin_Tab) && (
                <li className="">
                  <NavLink
                    className="s-sidebar__nav-link d-flex gap-3 align-items-center ps-4 text-decoration-none sidebar-menu-fs fs-14 black-color"
                    to="/sub-admin"
                    data-bs-toggle={window.innerWidth < 770 ? "offcanvas" : ""}
                    data-bs-target="#s-sidebar__nav"
                    aria-controls="s-sidebar__nav"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                    onClick={() => navigate("/sub-admin")}
                  >
                    <span>
                      <BsPerson className="fs-18" />
                    </span>
                    Subadmin
                  </NavLink>
                </li>
              )}

              {checkPermissionsInArray(AllPermissions.store_Report_Tab) && (
                <li className="">
                  <div className="accordion" id="accordionExample">
                    <div className="accordion-item border-0">
                      <h2 className="accordion-header " id="reports_">
                        <button
                          className="collapsed position-relative accordion-button bg-body shadow-none s-sidebar__nav-link d-flex gap-3 align-items-center ps-4 text-decoration-none sidebar-menu-fs fs-14 black-color rounded-0"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#reports"
                          aria-expanded="false"
                          aria-controls="reports"
                        >
                          <BiCart className="fs-18" />
                          Reports
                        </button>
                      </h2>
                      <div
                        id="reports"
                        className="accordion-collapse collapse"
                        aria-labelledby="reports_"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body p-0">
                          <ul className="list-unstyled">
                            {checkPermissionsInArray(
                              AllPermissions.store_Sales_Report
                            ) && (
                                <NavLink
                                  className="s-sidebar__nav-link d-flex gap-3 align-items-center ps-5 text-decoration-none sidebar-menu-fs fs-14 black-color"
                                  to="/report/sales"
                                  data-bs-toggle={
                                    window.innerWidth < 770 ? "offcanvas" : ""
                                  }
                                  data-bs-target="#s-sidebar__nav"
                                  aria-controls="s-sidebar__nav"
                                  aria-expanded="false"
                                  aria-label="Toggle navigation"
                                  onClick={() => navigate("/report/sales")}
                                >
                                  <BsArrowLeftRight className="fs-20" />
                                  <li className="sidebar-list-styled">
                                    Sales Report
                                  </li>
                                </NavLink>
                              )}
                            {checkPermissionsInArray(
                              AllPermissions.store_Sales_Return_Report
                            ) && (
                                <NavLink
                                  className="s-sidebar__nav-link d-flex gap-3 align-items-center ps-5 text-decoration-none sidebar-menu-fs fs-14 black-color"
                                  to="/report/sales-return"
                                  data-bs-toggle={
                                    window.innerWidth < 770 ? "offcanvas" : ""
                                  }
                                  data-bs-target="#s-sidebar__nav"
                                  aria-controls="s-sidebar__nav"
                                  aria-expanded="false"
                                  aria-label="Toggle navigation"
                                  onClick={() => navigate("/report/sales-return")}
                                >
                                  <BsArrowLeftRight className="fs-20" />
                                  <li className="sidebar-list-styled">
                                    Sales Return Report
                                  </li>
                                </NavLink>
                              )}
                            {checkPermissionsInArray(
                              AllPermissions.store_Inventory_Report
                            ) && (
                                <NavLink
                                  className="s-sidebar__nav-link d-flex gap-3 align-items-center ps-5 text-decoration-none sidebar-menu-fs fs-14 black-color"
                                  to="/report/inventory"
                                  data-bs-toggle={
                                    window.innerWidth < 770 ? "offcanvas" : ""
                                  }
                                  data-bs-target="#s-sidebar__nav"
                                  aria-controls="s-sidebar__nav"
                                  aria-expanded="false"
                                  aria-label="Toggle navigation"
                                  onClick={() => navigate("/report/inventory")}
                                >
                                  <BsArrowLeftRight className="fs-20" />
                                  <li className="sidebar-list-styled">
                                    Inventory Report
                                  </li>
                                </NavLink>
                              )}
                            {checkPermissionsInArray(
                              AllPermissions.store_Daily_Activity_Report
                            ) && (
                                <NavLink
                                  className="s-sidebar__nav-link d-flex gap-3 align-items-center ps-5 text-decoration-none sidebar-menu-fs fs-14 black-color"
                                  to="/report/daily-activity"
                                >
                                  <BsArrowLeftRight className="fs-20" />
                                  <li className="sidebar-list-styled">
                                    Daily Activity Report
                                  </li>
                                </NavLink>
                              )}
                            {checkPermissionsInArray(
                              AllPermissions.store_GST_OutPut_Report
                            ) && (
                                <NavLink
                                  className="s-sidebar__nav-link d-flex gap-3 align-items-center ps-5 text-decoration-none sidebar-menu-fs fs-14 black-color"
                                  to="/report/gst"
                                  data-bs-toggle={
                                    window.innerWidth < 770 ? "offcanvas" : ""
                                  }
                                  data-bs-target="#s-sidebar__nav"
                                  aria-controls="s-sidebar__nav"
                                  aria-expanded="false"
                                  aria-label="Toggle navigation"
                                  onClick={() => navigate("/report/gst")}
                                >
                                  <BsArrowLeftRight className="fs-20" />
                                  <li className="sidebar-list-styled">
                                    GST Output Report
                                  </li>
                                </NavLink>
                              )}
                            {checkPermissionsInArray(
                              AllPermissions.store_Inventory_Addition_Report
                            ) && (
                                <NavLink
                                  className="s-sidebar__nav-link d-flex gap-3 align-items-center ps-5 text-decoration-none sidebar-menu-fs fs-14 black-color"
                                  to="/report/inventory-addition"
                                  data-bs-toggle={
                                    window.innerWidth < 770 ? "offcanvas" : ""
                                  }
                                  data-bs-target="#s-sidebar__nav"
                                  aria-controls="s-sidebar__nav"
                                  aria-expanded="false"
                                  aria-label="Toggle navigation"
                                  onClick={() =>
                                    navigate("/report/inventory-addition")
                                  }
                                >
                                  <BsArrowLeftRight className="fs-20" />
                                  <li className="sidebar-list-styled">
                                    Inventory Addition Report
                                  </li>
                                </NavLink>
                              )}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              )}

              {checkPermissionsInArray(AllPermissions.store_Setting_Tab) && (
                <li className="">
                  <div className="accordion" id="accordionExample">
                    <div className="accordion-item border-0">
                      <h2 className="accordion-header " id="setting_">
                        <button
                          className="collapsed position-relative accordion-button bg-body shadow-none s-sidebar__nav-link d-flex gap-3 align-items-center ps-4 text-decoration-none sidebar-menu-fs fs-14 black-color rounded-0"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#setting"
                          aria-expanded="false"
                          aria-controls="setting"
                        >
                          <BiCart className="fs-18" />
                          Setting
                        </button>
                      </h2>
                      <div
                        id="setting"
                        className="accordion-collapse collapse"
                        aria-labelledby="setting_"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body p-0">
                          <ul className="list-unstyled">
                            <NavLink
                              className="s-sidebar__nav-link d-flex gap-3 align-items-center ps-5 text-decoration-none sidebar-menu-fs fs-14 black-color"
                              to="/setting"
                              data-bs-toggle={
                                window.innerWidth < 770 ? "offcanvas" : ""
                              }
                              data-bs-target="#s-sidebar__nav"
                              aria-controls="s-sidebar__nav"
                              aria-expanded="false"
                              aria-label="Toggle navigation"
                              onClick={() => navigate("/setting")}
                            >
                              <BsArrowLeftRight className="fs-20" />
                              <li className="sidebar-list-styled">Setting</li>
                            </NavLink>

                            <NavLink
                              className="s-sidebar__nav-link d-flex gap-3 align-items-center ps-5 text-decoration-none sidebar-menu-fs fs-14 black-color"
                              to="/settings/greeting"
                              data-bs-toggle={
                                window.innerWidth < 770 ? "offcanvas" : ""
                              }
                              data-bs-target="#s-sidebar__nav"
                              aria-controls="s-sidebar__nav"
                              aria-expanded="false"
                              aria-label="Toggle navigation"
                              onClick={() => navigate("/settings/greeting")}
                            >
                              <BsArrowLeftRight className="fs-20" />
                              <li className="sidebar-list-styled">
                                Greeting Message
                              </li>
                            </NavLink>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              )}

              {/* {checkPermissionsInArray(AllPermissions.store_Setting_Tab) &&  <li className="">
                <NavLink
                  className="s-sidebar__nav-link d-flex gap-3 align-items-center ps-4 text-decoration-none sidebar-menu-fs fs-14 black-color"
                  to="/setting"
                  data-bs-toggle={window.innerWidth < 770 ? "offcanvas" : ""}
                  data-bs-target="#s-sidebar__nav"
                  aria-controls="s-sidebar__nav"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                  onClick={() => navigate("/setting")}
                >
                  <span>
                    <RxDashboard className="fs-18" />
                  </span>
                  Setting
                </NavLink>
              </li>} */}

              {/* Hide Account section from the portal, will be able to show again if required  */}
              {checkPermissionsInArray(AllPermissions.store_Accounts_Tab) &&
                (showAccounts === constant?.dev_URL ||
                  showAccounts === constant?.uat_URL) ? (
                <li className="">
                  <div className="accordion" id="accordionExample">
                    <div className="accordion-item border-0">
                      <h2 className="accordion-header " id="accounts_">
                        <button
                          className="collapsed position-relative accordion-button bg-body shadow-none s-sidebar__nav-link d-flex gap-3 align-items-center ps-4 text-decoration-none sidebar-menu-fs fs-14 black-color rounded-0"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#accounts"
                          aria-expanded="false"
                          aria-controls="accounts"
                        >
                          <BiCart className="fs-18" />
                          Accounts
                        </button>
                      </h2>
                      <div
                        id="accounts"
                        className="accordion-collapse collapse"
                        aria-labelledby="accounts_"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body p-0">
                          <ul className="list-unstyled">
                            {checkPermissionsInArray(
                              AllPermissions.store_Transaction_Tab
                            ) && (
                                <NavLink
                                  className="s-sidebar__nav-link d-flex gap-3 align-items-center ps-5 text-decoration-none sidebar-menu-fs fs-14 black-color"
                                  to="/accounts/transaction"
                                  data-bs-toggle={
                                    window.innerWidth < 770 ? "offcanvas" : ""
                                  }
                                  data-bs-target="#s-sidebar__nav"
                                  aria-controls="s-sidebar__nav"
                                  aria-expanded="false"
                                  aria-label="Toggle navigation"
                                  onClick={() =>
                                    navigate("/accounts/transaction")
                                  }
                                >
                                  <BsArrowLeftRight className="fs-20" />
                                  <li className="sidebar-list-styled">
                                    Transaction
                                  </li>
                                </NavLink>
                              )}
                            {checkPermissionsInArray(
                              AllPermissions.store_General_Accounts_Tab
                            ) && (
                                <NavLink
                                  className="s-sidebar__nav-link d-flex gap-3 align-items-center ps-5 text-decoration-none sidebar-menu-fs fs-14 black-color"
                                  to="/accounts/general-account"
                                  data-bs-toggle={
                                    window.innerWidth < 770 ? "offcanvas" : ""
                                  }
                                  data-bs-target="#s-sidebar__nav"
                                  aria-controls="s-sidebar__nav"
                                  aria-expanded="false"
                                  aria-label="Toggle navigation"
                                  onClick={() =>
                                    navigate("/accounts/general-account")
                                  }
                                >
                                  <BsArrowLeftRight className="fs-20" />
                                  <li className="sidebar-list-styled">
                                    General Accounts
                                  </li>
                                </NavLink>
                              )}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              ) : (
                ""
              )}
            </ul>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default SideBar;
