import React, { useContext, useEffect, useState } from "react";
import { HeaderHeading } from "../../../../Context";
import Wrapper from "../../../../components/common/Wrapper/Wrapper";
import service from "../../../../api/services";
import organisms from "../../../../components/organisms";
import { Default_Page_Limit, Default_Page_Number } from "../../../../constants/defaultValues";
import { useSearchParams } from "react-router-dom";
import { getParams } from "../../../../constants/utils";
import constant from "../../../../constants/constant";

const { LostBarcodeHistory } = organisms

const Index = () => {
    const [searchParams] = useSearchParams();
    const [typeValue, setTypeValue] = useState<any>(getParams('history-type') == constant.BRAND_CATEGORY[0]?.value)
    const [initialLoading, setInitialLoading] = useState<boolean>(false)
    const [markBarcode, setMarkBarcode] = useState<boolean>(false)
    const [search, setSearch] = useState("")
    const [page, setPage] = useState(Default_Page_Number)
    const [limit, setLimit] = useState(Default_Page_Limit)
    const [lostBarcode, setLostBarcode] = useState<any>()
    const { setHeader }: any = useContext(HeaderHeading);

    useEffect(() => {
        setHeader("Barcode - Lost History")
        let tempValue: any = getParams('history-type')
        setTypeValue(tempValue == constant.BRAND_CATEGORY[0]?.value)
        setPage(Default_Page_Number)
        setLimit(Default_Page_Limit)

        setSearch('')
    }, [searchParams])

    const getLostBarcode = async () => {
        try {
            let response = await service.barcode.getLostBarcode({
                page, limit, "search": search,
                is_recognised: typeValue
            })
            if (response.status === 200) {
                setLostBarcode(response.data)
            }
        } catch (error: any) {
            console.log(error)
        }
    }

    useEffect(() => {
        getLostBarcode()
    }, [page,limit,search,typeValue])



    return (
        <Wrapper>
            <LostBarcodeHistory
                setSearch={(value: string) => setSearch(value)}
                lostBarcode={lostBarcode}
                currentPage={page}
                setCurrentPage={(value: number) => setPage(value)}
                setLimitPerPage={(value: number) => setLimit(value)}
                limit={limit}
                setMarkBarcode={(value: boolean) => setMarkBarcode(value)}
                markBarcode={markBarcode}
                typeValue={typeValue}
            />
        </Wrapper>
    )
}

export default Index