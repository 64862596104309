import Button from './Button';
import Image from './Image';
import CustomImages from './CustomImage';
import Search from './Search';
import Select from './Select';
import Table from './Table';
import Input from './Input';
import RadioInput from './RadioInput';
import TextArea from './TextArea';
import CustomSearch from './CustomSearch';
import Pagination from './Pagination';
import AvailableBadge from './AvailableBadge';
import Badges from './Badges'
import CustomTable from "./CustomTable"
import PendingBadge from './PendingBadge'
import StatusComponent from "./StatusComponent"
import CommonModal from "./CommonModal"
import FileInput from "./FileInput"
import TextEditor from "./TextEditor"

const atoms = {
    Button,
    Image,
    CustomImages,
    Search,
    Select,
    Table,
    Input,
    RadioInput,
    TextArea,
    CustomSearch,
    Pagination,
    AvailableBadge,
    Badges,
    PendingBadge,
    CustomTable,
    StatusComponent,
    CommonModal,
    FileInput,
    TextEditor,
}
export default atoms