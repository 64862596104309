import atoms from "../../../atoms";
import moment from "moment";
import {
    numberWithCommas,
} from "../../../../constants/utils";
import { IndexProps, Inventory } from "./interface";
import { useNavigate } from "react-router";
import { AiOutlineEye } from "react-icons/ai";
import constant from "../../../../constants/constant";
import molecules from "../../../molecules";
import { useEffect, useRef, useState } from "react";
import service from "../../../../api/services";
import ThreeDots from '../../../../assets/Images/threeDots.svg'
import { MdOutlineFileDownload } from "react-icons/md"
import { FiEdit } from "react-icons/fi";
import { RiDeleteBin6Line } from 'react-icons/ri';
const { Pagination, Search, Table, Input, Button, Image } = atoms;
const { LightBox } = molecules;

const Index = ({
    pendingInventoryData,
    setSearch,
    currentPage,
    setCurrentPage,
    setLimitPerPage,
    limit,
    updateVerificationStatus,
    isRecognized, handleDelete, showSidebarModal, setShowSidebarModal,handleDownloadInventory
}: IndexProps) => {
    const navigate = useNavigate();
    const inputRef: any = useRef()
    const [isViewData, setIsViewData] = useState<any>({})
    // const [showSidebarModal, setShowSidebarModal] = useState(false)

    const columns = [
        // { name: 'Stock Addition ID', selector: (row: Inventory) => row.invoice_id_display, sortable: true },
        { name: 'Date of Addition', selector: (row: Inventory) => row?.createdAt ? moment(row?.createdAt)?.format('DD/MM/YYYY') : '-', sortable: true },

        { name: 'Supplier Details', selector: (row: Inventory) => row?.local_supplier_name || row.supplier?.supplier_name },
        { name: 'Supplier Invoice No.', selector: (row: Inventory) => row?.supplier_invoice_number },
        { name: 'Supplier Invoice Date', selector: (row: Inventory) => moment(row?.supplier_invoice_date).format('DD/MM/YYYY'), },
        { name: 'Invoice Value (₹)', selector: (row: Inventory) => numberWithCommas(parseFloat(row?.total_amount?.toFixed(2))) },
        {
            name: 'Verification Status', selector: (row: any) => row.verification_status === constant.VERIFICATION_STATUS.PENDING ?
                (<div className="background-light-yellow border-product-radius"><p className="color-yellow m-1 p-1">{'Pending'}</p></div>) : row?.verification_status === constant.VERIFICATION_STATUS.VERIFIED ?
                    (<div className="background-light-green border-product-radius"><p className="color-green m-1 p-1">{"Approved"}</p></div>) : row?.verification_status === constant.VERIFICATION_STATUS.REWORK ?
                        (<div className="background-light-red border-product-radius"><p className="color-red m-1 p-1">{"Rework"}</p></div>) : '',
        },
        { name: 'Action', selector: (row: any) => row.action, minWidth: "120px" },
    ]

    const getViewData = async (id: string) => {
        try {
            let response = await service.inventory.getDetailById(id)
            setIsViewData(response.data.data)
            setShowSidebarModal(true)
        } catch (error: any) {
            console.log(error)
            // addToast(error?.response?.data?.message, {
            //     appearance: 'error',
            //     autoDismiss: true,
            //     autoDismissTimeout: 3000,
            // });
        }
    }

    useEffect(() => {
        if (inputRef?.current) {
            inputRef.current.value = "";
        }
    }, [isRecognized]);
    return (
        <>
            <div className="row">
                <div className="col-12 col-md-4 mb-lg-0 mb-3">
                    <Search
                        refs={inputRef}
                        searchCallBack={(e: string) => {
                            setSearch(e);
                        }}
                        className="w-100"
                    />
                </div>
                <div className="col-12 col-md-8 gap-2 d-lg-flex justify-content-lg-end px-lg-0">
                    <div className="d-flex justify-content-lg-center justify-content-end">
                        <Button onClick={() => navigate(`/inventory/add?isRecognized=${isRecognized}`)}>
                            Add Inventory
                        </Button>
                    </div>
                </div>
            </div>
            <div>
                <Table
                    columns={columns}
                    AdditionalClassName="table-height-50"
                    data={pendingInventoryData?.data?.map((item: any) => {
                        return {
                            ...item,
                            action: (
                                <> <div className='table-data-width'>
                                    <button
                                        className="btn dropdown-dots shadow-none action-button"
                                        type="button"
                                        id="dropdownMenuButton1"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                    >
                                        <Image src={ThreeDots} />
                                    </button>
                                    <ul
                                        className="dropdown-menu dropdown-menu-end dropdown-style"
                                        aria-labelledby="dropdownMenuButton1"
                                    >
                                        <li>
                                            {/* {item.verification_status === constant.VERIFICATION_STATUS.VERIFIED ? ( */}
                                            <button
                                                className="dropdown-item dropdown-item-size"
                                                onClick={() => getViewData(item.id)}
                                            >
                                                <div className='d-flex align-items-center'>
                                                    <AiOutlineEye />
                                                    <span className='ms-2 mb-0'>View</span>
                                                </div>
                                            </button>
                                        </li>
                                        {
                                        // (item.category === constant.STORE_INVOICE_CATEGORY[0].value || item.category === constant.STORE_INVOICE_CATEGORY[1].value) && 
                                        <>
                                            <li>
                                                <hr className="my-1"></hr>
                                            </li>

                                            <li>
                                                <button
                                                    className="dropdown-item dropdown-item-size"
                                                    onClick={() => navigate(`/inventory/edit/${item.id}?isRecognized=${isRecognized}`)}
                                                >
                                                    <div className='d-flex align-items-center'>
                                                        <FiEdit />
                                                        <span className='ms-2 mb-0'>Edit</span>
                                                    </div>
                                                </button>
                                                {/* )} */}
                                            </li>
                                        </>}

                                        {!isRecognized &&
                                            <>
                                                <li>
                                                    <hr className="my-1"></hr>
                                                </li>
                                                <li>
                                                    <button
                                                        className="dropdown-item dropdown-item-size "
                                                        onClick={() => handleDelete(item?.id)}
                                                    >
                                                        <div className='d-flex align-items-center text-danger'>
                                                            <RiDeleteBin6Line />
                                                            <span className='ms-2 mb-0'>Delete</span>
                                                        </div>
                                                    </button>

                                                </li>
                                            </>
                                        }
                                         {
                                        // (item.category === constant.STORE_INVOICE_CATEGORY[0].value || item.category === constant.STORE_INVOICE_CATEGORY[1].value) && 
                                        <>
                                            <li>
                                                <hr className="my-1"></hr>
                                            </li>

                                            <li>
                                                <button
                                                    className="dropdown-item dropdown-item-size"
                                                    onClick={() => handleDownloadInventory(item?.id)}
                                                >
                                                    <div className='d-flex align-items-center'>
                                                    <MdOutlineFileDownload />
                                                        <span className='ms-2 mb-0'>Download</span>
                                                    </div>
                                                </button>
                                                {/* )} */}
                                            </li>
                                        </>}
                                    </ul>
                                </div></>
                            ),
                        };
                    })}
                />
            </div>
            <div>
                <Pagination
                    totalCount={pendingInventoryData?.count}
                    currentPage={currentPage}
                    totalPages={pendingInventoryData?.totalPages}
                    onChangePage={(page: any) => setCurrentPage(page)}
                    setLimit={(e: number) => setLimitPerPage(e)}
                    limit={limit}
                />
            </div>
            {showSidebarModal && <div>
                <LightBox
                    show={showSidebarModal}
                    onHide={() => setShowSidebarModal(false)}
                    isViewData={isViewData}
                    isVisible={!isRecognized}
                    updateVerificationStatus={(id: string, status: number) => {
                        updateVerificationStatus(id, status)
                    }}
                />
            </div>}
        </>
    );
};

export default Index;
