import React, { useContext, useEffect, useState } from "react";
import Wrapper from "../../../../components/common/Wrapper/Wrapper";
import organisms from "../../../../components/organisms";
import service from "../../../../api/services";
import { Default_Page_Limit, Default_Page_Number } from "../../../../constants/defaultValues";
import { HeaderHeading } from '../../../../Context'
import { BASE_URL } from "../../../../constants/defaultValues"
import constant from "../../../../constants/constant";
import { useSearchParams } from "react-router-dom";
import { getContactLensType, getParams } from "../../../../constants/utils";

const { PendingProduct } = organisms

const Index = () => {
    const [searchParams] = useSearchParams();
    const [typeValue, setTypeValue] = useState<any>(getParams('pending_product_type'))
    const [productData, setProductData] = useState<any>({})
    const [currentPage, setCurrentPage] = useState(Default_Page_Number);
    const [productDetail, setProductDetail] = useState({});
    const [limitPerPage, setLimitPerPage] = useState(Default_Page_Limit);
    const [search, setSearch] = useState('');
    const { setHeader, }: any = useContext(HeaderHeading);
    const [productType, setProductType] = useState<any>([])
    const [newProductData, setNewProductData] = useState<any>({})
    const [brandResponse, setBrandResponse] = useState([])
    const [isContactLensProduct, setIsContactLensProduct] = useState<boolean>(getContactLensType())

    const getDropdown = (response: []) => {
        let dropdown: any = []
        response && response.map((value: any) => {
            dropdown.push({
                "value": value.id || value.product_type.id,
                "label": value.name || value.brand_name || value.color_name || value.product_type.name,
                "code": value?.brand_code || value?.product_type_initials || value?.product_type?.product_type_initials
            }
            )
        })
        return dropdown
    }

  
    const getAllDetail = async () => {
        const brands = newProductData?.brand_id?.map((item: any) => item?.value)
        const product_types = newProductData?.product_type_id?.map((item: any) => item?.value)
        let NewBrands = JSON.stringify(brands)
        let NewProductTypes = JSON.stringify(product_types)
        try {
            let response = await service.product.view(
                {
                    "page": currentPage,
                    "search": search,
                    "limit": limitPerPage,
                    "status": constant.VERIFICATION_STATUS.PENDING,
                    "brands": NewBrands,
                    "product_types": NewProductTypes,
                    "modal_number": newProductData?.modal_number,
                    brand_category: typeValue,
                    "is_contact_lens": isContactLensProduct
                }
            )
            if (response.status === 200) {
                setProductData(response.data)
                localStorage.removeItem('isContactLensProduct')
            }
        } catch (error) {
            console.log(error)
        }
    }

    const getDetailById = async (id: string) => {
        try {
            let response = await service.product.getDetailById(id)
            if (response.status === 200)

                setProductDetail(response.data.data)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        setProductDetail({})
        setTypeValue(getParams('pending_product_type'))
        setCurrentPage(Default_Page_Number)
        setLimitPerPage(Default_Page_Limit)
        setSearch('')
        // setIsContactLensProduct(false)
    }, [searchParams])

  
    useEffect(() => {
        setHeader("Product - Pending")
        // setSidebar(false)
        getAllDetail()
    }, [currentPage, typeValue, limitPerPage, search, isContactLensProduct])

    return (
        <Wrapper>
            <PendingProduct
                productResponse={productData}
                productDetail={productDetail && productDetail}
                updateVerificationStatus={(id: string, status: number) => { }}
                getDetailById={(id: string) => { getDetailById(id) }}
                setLimitPerPage={setLimitPerPage}
                setCurrentPage={setCurrentPage}
                setSearch={setSearch} currentPage={currentPage} limit={limitPerPage}
                handleProductDelete={''}
                productType={productType}
                setProductData={setNewProductData}
                brandResponse={brandResponse}
                setProductType={setProductType}
                getDropdown={getDropdown}
                handleDownload={''}
                isRecognize={typeValue == constant.BRAND_CATEGORY[0].value}
                setIsContactLensProduct={setIsContactLensProduct}
                isContactLensProduct={isContactLensProduct}
            />
        </Wrapper>
    )

}
export default Index